import { SecondaryButton } from "commons/buttons";
import EyeIcon from "assets/svg/EyeIcon";
import { EditIcon } from "assets";

export const columnsBilling = ({ openBillingModal }) => [
  {
    field: "businessName",
    Header: "Razón social",
    id: "businessName",
    accessor: "businessName",
    disableSortBy: true,
  },
  {
    field: "invoice_receiver",
    Header: "Receptor de Factura",
    id: "invoice_receiver",
    accessor: "invoice_receiver",
    disableSortBy: true,
    Cell: ({ row }) => {
      return row.original.accountantUserId ? "Contador" : "Empresa";
    },
  },
  {
    field: "email",
    Header: "Email",
    id: "email",
    accessor: "email",
    disableSortBy: true,
    Cell: ({ row }) => {
      return `${row.original.email}`;
    },
  },
  {
    field: "action",
    Header: "Acción",
    headerName: "action",
    id: "action",
    disableSortBy: true,
    Cell: ({ row }) => {
      return (
        <div style={{ display: "flex", flexDirection: "row", gap: "6px" }}>
          <SecondaryButton
            endIcon={<EyeIcon width={18} height={18} />}
            text={"Ver Detalle"}
            innerStyle={{ justifyContent: "space-between" }}
            style={{ padding: "0px 10px" }}
            action={() => openBillingModal(row.original.id, false)}
            width="110px"
          />
          <SecondaryButton
            endIcon={<EditIcon width={18} height={18} />}
            text={"Editar"}
            innerStyle={{ justifyContent: "space-between" }}
            action={() => openBillingModal(row.original.id, true)}
          />
        </div>
      );
    },
  },
];
