import React, { useMemo, useState, useEffect, useContext } from "react";
import {
  Autocomplete,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Submenu, Table, TableSkeleton } from "../../commons";
import { receiptsColumns, paymentsScreens } from "../../utils";
import { styles } from "./styles";
import { SearchSuppliers } from "../../services";
import {
  PaymentsContext,
  TransactionsContext,
  UserContext,
} from "../../contexts";
import { TableEmptyState } from "../../commons/Layout/EmptyState";
import { StatusFilter } from "./components";
import { useMediaQuery } from "react-responsive";
import { SearchIcon } from "../../assets";
import { useChat } from "../../hooks";
import { useNavigate, useLocation } from "react-router-dom";
import { ModalSiigo } from "./components/ModalSiigo";
import { ReceiptDetails } from "commons/Sidebars";

export const ReceiptsScreen = () => {
  const navigate = useNavigate();
  const [pageIndex, setPageIndex] = useState(1);
  const [totals, setTotal] = useState();
  const [totalPages, setTotalPages] = useState(1);
  const { invoiceReceipts, getReceipts, getTotalReceipts } =
    useContext(TransactionsContext);
  const { receiptsScreen } = useContext(PaymentsContext);
  const [detailsModalIsOpen, setDetailModalIsOpen] = useState(false);
  const [selectedTrx, setSelectedTrx] = useState();
  const [statusSelected, setStatusSelected] = useState("all");
  const [searchOptions, setSearchOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });
  const providerIdSelected = receiptsScreen?.providerIdSelected?.value;
  const setProviderIdSelected = receiptsScreen?.providerIdSelected?.setter;
  const [openModalSiigoError, setOpenModalSiigoError] = useState({
    open: false,
    type: "",
  });
  const { canDisplayAssistedEgress } = useContext(UserContext);
  useChat();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const errorParams = queryParams.get("error");

  useEffect(() => {
    if (errorParams) {
      const documentNumber = queryParams.get("documentNumber");
      setOpenModalSiigoError({
        open: true,
        type: errorParams,
        documentNumber: documentNumber ? documentNumber : "",
      });
    }
  }, [errorParams]);

  useEffect(() => {
    getReceipts(pageIndex, providerIdSelected, statusSelected).finally(() => {
      setLoading(false);
    });
    getTotalReceipts(providerIdSelected, statusSelected).then((res) => {
      setTotal(res);
    });
  }, [pageIndex, providerIdSelected, statusSelected]);

  useEffect(() => {
    if (totals) {
      if (totals.total > 100) {
        setTotalPages(Math.ceil(totals.total / 100));
      } else {
        setTotalPages(1);
      }
    }
  }, [totals]);

  const getSearchOptions = (query) => {
    SearchSuppliers(query).then((res) => {
      setSearchOptions(res);
    });
  };

  const handleSearchChange = (sup) => {
    if (sup) {
      setProviderIdSelected(sup.id);
      receiptsScreen?.supplierFilter?.setter(sup);
    } else {
      setProviderIdSelected("all");
      receiptsScreen?.supplierFilter?.setter({});
    }
    setPageIndex(1);
  };

  const columns = useMemo(() => receiptsColumns, []);

  const openDetailsModal = (id) => {
    setSelectedTrx(id);
    setDetailModalIsOpen(true);
  };

  const renderAutocomplete = () => {
    return (
      <Autocomplete
        options={searchOptions}
        size="small"
        sx={{ width: isMobile ? "100%" : "35%", minWidth: "164px" }}
        freeSolo
        getOptionLabel={(option) => {
          return option?.name || "";
        }}
        onChange={(event, option) => {
          if (option?.id) {
            handleSearchChange(option);
          } else {
            handleSearchChange();
          }
        }}
        onInputChange={(event) => {
          if (event?.target?.value?.length >= 3) {
            getSearchOptions(event.target.value);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              style: { fontSize: 14 },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            placeholder={"Buscar proveedor"}
          />
        )}
        value={
          receiptsScreen?.supplierFilter?.value || {
            name: "",
          }
        }
      />
    );
  };

  const redirectToExpenses = (documentNumber, receiptId) => {
    navigate(`/expenses/${documentNumber}/${receiptId}`);
  };

  const handleOnCloseModal = () => {
    setOpenModalSiigoError({ open: false, type: "" });
    const currentPath = location.pathname;
    navigate(currentPath, { replace: true });
  };

  return (
    <>
      <ModalSiigo state={openModalSiigoError} onClose={handleOnCloseModal} />
      <ReceiptDetails
        visible={detailsModalIsOpen}
        onClose={() => setDetailModalIsOpen(false)}
        id={selectedTrx}
      />
      <div>
        <Submenu items={paymentsScreens} />
        <div className="layout-container">
          <div style={styles.titleContainer}>
            <Typography sx={styles.title} noWrap>
              Soportes
            </Typography>
          </div>
          <StatusFilter
            statusSelected={statusSelected}
            setStatusSelected={setStatusSelected}
          />
          {loading ? (
            <TableSkeleton />
          ) : (
            <Table
              columns={columns(redirectToExpenses, canDisplayAssistedEgress)}
              data={invoiceReceipts}
              setPageIndex={setPageIndex}
              section="receipts"
              moneyIcon
              expandIcon
              expandAction={openDetailsModal}
              checkboxVisible={false}
              pageCount={totalPages}
              deleteIcon
              showAutocomplete={true}
              hasFileIcon
              supplier={receiptsScreen?.supplierFilter?.value}
              setSupplier={receiptsScreen?.supplierFilter?.setter}
              renderAutocomplete={renderAutocomplete}
              emptyState={
                <TableEmptyState
                  title={"Tu empresa aún no tiene soportes"}
                  subtitle={
                    "Aquí se mostrarán los pagos o agrupamientos de facturas que realices."
                  }
                />
              }
            />
          )}
        </div>
      </div>
    </>
  );
};
