import { Dropdown } from "react-bootstrap";
import { ChevronDownIcon, ChevronUpIcon, PlusIcon } from "../../assets";

export const DropdownButton = ({
  text,
  disabled = false,
  type = "dropdown",
  style = {},
  open,
  openedIcon,
  closedIcon,
}) => {
  const styles = {
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    height: "40px",
    color: "#344054",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
    border: "1px solid #D0D5DD",
    outline: "0",
    boxShadow: "0 0 0 0.25rem rgb(13 110 253 / 0%)",
    padding: "8px 16px 8px 12px",
    ...style,
  };

  return (
    <Dropdown.Toggle disabled={disabled} style={styles} id="dropdown-basic">
      <div
        style={{
          fontSize: "14px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        {type === "dropdown" ? (
          <>
            <span style={{ paddingRight: 6 }}>{text}</span>
            {open ? openedIcon : closedIcon}
          </>
        ) : (
          <>
            <PlusIcon stroke="#344054" />
            <span style={{ paddingLeft: 6, alignSelf: "center" }}>{text}</span>
          </>
        )}
      </div>
    </Dropdown.Toggle>
  );
};
