import React, { useContext, useEffect, useRef, useState } from "react";
import * as amplitude from "@amplitude/analytics-browser";
import { Typography } from "@mui/material";
import { SecondaryButton, ThirdButton } from "commons/buttons";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { SyncIcon } from "assets";
import { formatLocaleDate, saveInLocalStorage } from "utils";
import { CustomSkeleton } from "commons";
import { Spinner } from "react-bootstrap";
import { ExportReconciliationsFile, syncSiigoReconciliations } from "services";
import { CloseDropdown } from "./CloseDropdown";
import createReconciliationsXLSX from "commons/modals/PickDatesToExportModal/downloadReconciliationsXLSX";
import { toast } from "react-toastify";
import { toastOptions } from "constants";
import { UserContext } from "contexts";
import { addDays } from "date-fns";

export const Banks = ({
  banks,
  selectedBank,
  process,
  setSelectedProcessId,
  setSelectedBank,
  selectedProcessId,
  setIsEndPeriodModalVisible,
  refreshBankMovementsTable,
}) => {
  const { currentUser } = useContext(UserContext);
  const [syncingSiigo, setSyncingSiigo] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const closeRef = useRef(null);
  const open = !!anchorEl;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    closeRef?.current?.focus();
  };

  useEffect(() => {
    saveInLocalStorage("reconciliationsBankId", banks.at(0).id);
  }, [banks]);

  const BankLabel = ({ bank }) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "8px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {bank.logo && (
          <img
            style={{
              height: "23px",
              width: "23px",
              marginRight: "2px",
            }}
            src={bank.logo}
            alt="bankLogo"
          />
        )}

        <Typography
          style={{
            fontSize: "18px",
            color: "#344054",
            textTransform: "capitalize",
            width: "100%",
            fontWeight: "600",
          }}
        >
          {bank.bank_alias}
        </Typography>
      </div>
    );
  };

  const syncSiigo = () => {
    setSyncingSiigo(true);
    syncSiigoReconciliations({
      reconciliationBankId: selectedBank.id,
      reconciliationProcessId: selectedProcessId,
    }).then((res) => {
      setSyncingSiigo(false);
      toast.success(
        "Registros de Siigo sincronizados con éxito.",
        toastOptions
      );
      refreshBankMovementsTable();
    });
  };

  const runExport = () => {
    ExportReconciliationsFile(selectedProcessId)
      .then((response) =>
        createReconciliationsXLSX({
          response,
          fileName: `Conciliación_${selectedBank.name}_${formatLocaleDate(
            process?.reconciliationProcess?.start_date,
            "yyyy-MM-dd"
          )}_${formatLocaleDate(
            process?.reconciliationProcess?.end_date,
            "yyyy-MM-dd"
          )}_Payana`,
        })
      )
      .then(() => {
        amplitude.logEvent("DOWNLOAD_CONCILIATION_REPORT", {
          user_id: currentUser.id,
        });
        toast.success("¡Archivo exportado con éxito!", toastOptions);
      })
      .catch((error) => {
        toast.error(
          "Hubo un error exportando el archivo, por favor intente nuevamente.",
          toastOptions
        );
      });
  };

  return (
    <>
      {process ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              gap: "2px",
            }}
          >
            <BankLabel bank={selectedBank} />
            <Typography
              sx={{ fontSize: "14px", color: "#344054", fontWeight: "500" }}
            >
              {formatLocaleDate(
                addDays(
                  new Date(process?.reconciliationProcess?.start_date),
                  1
                ),
                "dd 'de' MMMM yyyy"
              )}{" "}
              al{" "}
              {formatLocaleDate(
                addDays(new Date(process?.reconciliationProcess?.end_date), 1),
                "dd 'de' MMMM yyyy"
              )}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: "8px",
            }}
          >
            <SecondaryButton
              action={() => runExport()}
              text={"Descargar conciliación"}
              width={"190px"}
            />
            <ThirdButton
              text={
                syncingSiigo ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  "Sincronizar Siigo"
                )
              }
              action={() => syncSiigo()}
              width={"190px"}
              icon={!syncingSiigo && <SyncIcon color="#6938EF" />}
              style={{
                color: "#6938EF",
                fontSize: "14px",
                fontWeight: "500",
              }}
              contentStyle={{ justifyContent: "center" }}
            />
            <CloseDropdown
              setSelectedProcessId={setSelectedProcessId}
              setSelectedBank={setSelectedBank}
              open={open}
              action={handleClick}
              setIsEndPeriodModalVisible={setIsEndPeriodModalVisible}
            />
          </div>
        </div>
      ) : (
        <div
          className="table-skeleton-page-initial-loading"
          style={{ width: "100%", marginBottom: "16px", padding: "0px" }}
        >
          <CustomSkeleton rows={4} />
        </div>
      )}
    </>
  );
};
