import { WarningIcon } from "assets";
import { PrimaryButton } from "commons/buttons";
import "./debt-list-empty-state.css";

export const DebtListEmptyState = ({ backToServiceCompanySearch }) => {
  return (
    <div className="container">
      <WarningIcon />
      <div className="title">
        No encontramos un servicio o cuentas asociadas a tu búsqueda
      </div>
      <div>
        Revisa los datos ingresados. Si son correctos, es posible que la empresa
        aún no haya cargado tu factura.
      </div>
      <div className="button-container">
        <PrimaryButton
          text="Revisar información"
          action={backToServiceCompanySearch}
          width="150px"
        />
      </div>
    </div>
  );
};
