import { createContext, useState } from "react";
import { GetBankMovements } from "../services";
import { MultiAccountModal } from "../commons/modals";

export const ReconciliationsContext = createContext();

export function ReconciliationsProvider({ children }) {
  const [multiAccountModalIsOpen, setMultiAccountModalIsOpen] = useState(false);
  const [syncIsLoading, setSyncIsLoading] = useState(false);
  const [syncHasError, setSyncHasError] = useState(false);
  const [syncBtnIsDisabled, setSyncBtnIsDisabled] = useState(false);
  const [statusSelected, setStatusSelected] = useState("not_reconciled");
  const [perPageSelected, setPerPageSelected] = useState(50);
  const [amountSignSelected, setAmountSignSelected] = useState("all");
  const [sortingSelected, setSortingQuery] = useState("");
  const [pageIndex, setPageIndex] = useState(1);
  const [selectedBank, setSelectedBank] = useState([]);
  const [selectedProcess, setSelectedProcess] = useState(null);
  const [selectedProcessId, setSelectedProcessId] = useState(null);
  const [banks, setBanks] = useState([]);
  const [activeProcesses, setActiveProcesses] = useState([]);

  const [
    beneficiaryForReconciliationsScreen,
    setBeneficiaryForReconciliationsScreen,
  ] = useState({});
  const [
    beneficiaryIdSelectedForReconciliationsScreen,
    setBeneficiaryIdSelectedForReconciliationsScreen,
  ] = useState("all");

  const [bankMovements, setBankMovements] = useState([]);

  const internalGetBankMovements = async (
    pageIndex,
    status,
    startDate,
    endDate,
    perPage,
    amountSign,
    processId
  ) => {
    const gottenBankMovements = await GetBankMovements({
      pageIndex: pageIndex === 0 ? 1 : pageIndex,
      status: status || statusSelected,
      perPage: perPage || perPageSelected,
      startDate: startDate || "all",
      endDate: endDate || "all",
      amountSign: amountSign || amountSignSelected,
      processId: processId || selectedProcessId,
    });
    if (gottenBankMovements) {
      setBankMovements(gottenBankMovements);
    }
  };

  return (
    <ReconciliationsContext.Provider
      value={{
        bankMovements,
        internalGetBankMovements,
        reconciliationsScreen: {
          beneficiaryFilter: {
            value: beneficiaryForReconciliationsScreen,
            setter: setBeneficiaryForReconciliationsScreen,
          },
          beneficiaryIdSelected: {
            value: beneficiaryIdSelectedForReconciliationsScreen,
            setter: setBeneficiaryIdSelectedForReconciliationsScreen,
          },
          pageIndex,
          setPageIndex,
        },
        setBankMovements,
        syncIsLoading,
        setSyncIsLoading,
        syncHasError,
        setSyncHasError,
        syncBtnIsDisabled,
        setSyncBtnIsDisabled,
        multiAccountModalIsOpen,
        setMultiAccountModalIsOpen,
        statusSelected,
        setStatusSelected,
        perPageSelected,
        setPerPageSelected,
        sortingSelected,
        setSortingQuery,
        selectedBank,
        setSelectedBank,
        banks,
        setBanks,
        amountSignSelected,
        setAmountSignSelected,
        selectedProcess,
        setSelectedProcess,
        selectedProcessId,
        setSelectedProcessId,
        activeProcesses,
        setActiveProcesses,
      }}
    >
      {multiAccountModalIsOpen && <MultiAccountModal />}
      {children}
    </ReconciliationsContext.Provider>
  );
}
