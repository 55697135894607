export const phoneChars = (phone) => {
  const prefixMap = {
    "+1": 12,
    "+34": 12,
    "+502": 12,
    "+507": 16,
  };

  const phoneString = phone.toString();

  const matchingPrefix = Object.keys(prefixMap).find((prefix) =>
    phoneString.startsWith(prefix)
  );

  return matchingPrefix ? prefixMap[matchingPrefix] : 13;
};

const isPhoneValid = (phone, required) => {
  try {
    if (required) {
      if (phone.length <= 3) {
        return "Este campo no puede quedar incompleto.";
      } else {
        return (
          phone.length === phoneChars(phone) ||
          `El número ingresado debe tener ${phoneChars(phone)} caracteres.`
        );
      }
    } else {
      return (
        phone.length === 0 ||
        phone.length === 3 ||
        phone.length === phoneChars(phone) ||
        `El número ingresado debe tener ${phoneChars(phone)} caracteres.`
      );
    }
  } catch (error) {
    return false;
  }
};

export const PHONE_NUMBER_VALIDATOR = (required = false) => {
  return {
    required: required,
    validate: (value) => isPhoneValid(value, required),
  };
};

export const EMAIL_VALIDATOR = (required = false) => {
  return {
    required: required,
    pattern: {
      value: /\S+@\S+\.\S+/,
      message:
        "El formato de correo no es válido. Verifica e intenta de nuevo.",
    },
  };
};

export const documentTypesSpecs = {
  nit: {
    regex: /[^\d]/g,
    minLength: 9,
    maxLength: 9,
    errorMessage:
      "El NIT que ingresaste es inválido. Tiene que tener 9 dígitos.",
    placeholder: "Ejemplo: 123456789",
    hint: "Coloca el número sin dígito de verificación.",
  },
  cc: {
    regex: /[^\d]/g,
    minLength: 6,
    maxLength: 10,
    errorMessage:
      "La cédula que ingresaste es inválida. Tiene que tener mínimo 6 dígitos.",
    placeholder: "Ejemplo: 123456",
    hint: "Coloca el número sin dígito de verificación.",
  },
  ce: {
    regex: /[^\dA-Za-z]/g,
    minLength: 5,
    maxLength: 15,
    errorMessage:
      "La cédula que ingresaste es inválida. Tiene que tener mínimo 5 dígitos.",
    placeholder: "Ejemplo: 12345",
    hint: "Puede contener letras y números.",
  },
  ti: {
    regex: /[^\d]/g,
    minLength: 10,
    maxLength: 11,
    errorMessage:
      "La TI que ingresaste es inválida. Tiene que tener mínimo 10 dígitos.",
    placeholder: "Ejemplo: 1234567890",
    hint: "Tiene que tener entre 10 y 11 dígitos.",
  },
  pasaporte: {
    regex: /[^\dA-Za-z]/g,
    minLength: 8,
    maxLength: 12,
    errorMessage:
      "El pasaporte que ingresaste es inválido. Tiene que tener mínimo 8 dígitos.",
    placeholder: "Ejemplo: ABC123456",
    hint: "Puede contener letras y números.",
  },
  ppt: {
    regex: /[^\dA-Za-z]/g,
    minLength: 7,
    maxLength: 11,
    errorMessage:
      "EL PPT que ingresaste es inválido. Tiene que tener mínimo 7 dígitos.",
    placeholder: "Ejemplo: 1234567",
    hint: "Puede contener letras y números.",
  },
};

const isDocumentValid = (required, documentNumber, documentType) => {
  try {
    if (!required) {
      return true;
    } else if (documentNumber.length === 0) {
      return "Este campo no puede quedar incompleto.";
    } else if (
      documentNumber.length <
      documentTypesSpecs[documentType.toLowerCase()].minLength
    ) {
      return documentTypesSpecs[documentType.toLowerCase()].errorMessage;
    } else {
      return true;
    }
  } catch (error) {
    return false;
  }
};

export const DOCUMENT_NUMBER_VALIDATOR = (
  required = true,
  documentType = null,
  handleChange = () => {}
) => {
  return {
    required: required,
    validate: (value) => isDocumentValid(required, value, documentType),
    onChange: (e) => handleChange(e.target.value),
  };
};

export const ACCOUNT_NUMBER_VALIDATOR = () => {
  return {
    required: true,
    pattern: {
      value: /^[0-9]*$/,
      message: "El número de cuenta debe ser numérico",
    },
    maxLength: {
      value: 30,
      message: "No puede superar los 30 caracteres",
    },
  };
};

const validators = {
  required: { required: true },
  alphanumeric: {
    pattern: {
      value: /^[a-zA-Z0-9]*$/,
      message: "Solo se permiten letras y números",
    },
  },
};

export const getValidators = (...validatorsKeys) => {
  return validatorsKeys.reduce((acc, validatorsKey) => {
    const validator = validators[validatorsKey];
    if (validator) {
      return {
        ...acc,
        ...validator,
      };
    }
    return acc;
  }, {});
};
