import React, { useRef, useState } from "react";
import { styles } from "../styles";
import { CloseButton } from "./CloseButton";
import { Divider, Menu, MenuItem } from "@mui/material";

export const CloseDropdown = ({
  setSelectedProcessId,
  setSelectedBank,
  setIsEndPeriodModalVisible,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const closeRef = useRef(null);
  const open = !!anchorEl;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    closeRef?.current?.focus();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <CloseButton open={open} action={handleClick} />
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          slotProps={{
            paper: {
              style: {
                height: 100,
                minHeight: 100,
                maxHeight: 100,
              },
            },
          }}
        >
          <MenuItem
            onClick={() => {
              setSelectedProcessId(null);
              setSelectedBank(null);
            }}
          >
            <div style={styles.dropdownItemContainer} type="dropdown">
              <div style={styles.dropdownItemTextContainer}>
                <p style={styles.dropdownItemText}>Guardar para más tarde</p>
              </div>
            </div>
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              setIsEndPeriodModalVisible(true);
            }}
          >
            <div style={styles.dropdownItemContainer} type="dropdown">
              <div
                style={{
                  ...styles.dropdownItemTextContainer,
                  marginTop: "0px",
                }}
              >
                <p style={styles.dropdownItemText}>Finalizar período</p>
              </div>
            </div>
          </MenuItem>
        </Menu>
      </div>
    </>
  );
};
