import { useEffect, useRef } from "react";
import { Stack } from "@mui/material";
import { PrimaryButton } from "../../../buttons";
import { MarkIcon } from "../../../../assets/svg/MarkIcon";
import { PayanaModalLogo } from "../../../../assets/svg/PayanaModalLogo";
import "./fade-in.css";

function SubscriptionWelcomeMessage({ resolveNextStep }) {
  return (
    <div>
      <PayanaModalLogo />
      <p className="dian-modal-title" style={{ fontWeight: 600, fontSize: 18 }}>
        Pagos más simples,
        <br />
        contabilidad más ordenada.
      </p>
      <div
        style={{
          border: "1px solid #D0D5DD",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        }}
      >
        <div
          style={{
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            backgroundColor: "#F2F4F7",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "12px 16px 20px 16px",
          }}
        >
          <span
            className="subscription-welcome-message-amount-comission"
            style={{
              fontWeight: 600,
              fontSize: "36px",
            }}
          >
            $3.300
          </span>
          <span
            style={{
              color: "#475467",
              fontWeight: 500,
              fontSize: "14px",
            }}
          >
            por beneficiario
          </span>
        </div>
        <div style={{ padding: "20px 16px", overflow: "hidden" }}>
          <div style={{ display: "flex", flexDirection: "column", gap: 12 }}>
            <FeatureListItem index={1} label="Pagos a proveedores" />
            <FeatureListItem index={2} label="Pagos a nómina" />
            <FeatureListItem
              index={3}
              label="Notificaciones de pagos automáticas"
            />
            <FeatureListItem
              index={4}
              label="Automatización de la contabilidad"
            />
            <FeatureListItem index={5} label="Reportes de conciliación" />
          </div>
        </div>
      </div>
      <Stack
        direction="row"
        justifyContent="space-between"
        style={{ width: "100%", marginTop: 20 }}
      >
        <PrimaryButton
          width="100%"
          text="Continuar"
          isDisable={false}
          loading={false}
          action={resolveNextStep}
        />
      </Stack>
    </div>
  );
}

function FeatureListItem({ label, index }) {
  const listItemRef = useRef(null);

  useEffect(() => {
    listItemRef.current.style.animation = `slideIn 0.3s ease-in-out ${
      index * 0.2
    }s both`;
  }, [index]);

  return (
    <div
      ref={listItemRef}
      style={{
        display: "flex",
        alignItems: "center",
        gap: 6,
        fontSize: 14,
      }}
    >
      <MarkIcon />
      {label}
    </div>
  );
}

export default SubscriptionWelcomeMessage;
