import React from "react";
import { Dropdown } from "react-bootstrap";
import {
  CheckCircleIcon,
  CheckDoneIcon,
  PlusCircleIcon,
} from "../../../assets";
import { styles } from "../styles";
import { Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { updateInvoices } from "services";
import { toast } from "react-toastify";
import { toastOptions } from "constants";

export function CausationDropdown({ row, refreshTable }) {
  const navigate = useNavigate();

  const navigateToCausationInvoice = (row) => {
    return navigate(`/payments/causation/${row.id}`, {
      replace: true,
      ...(!row.has_siigo_causation && {
        state: {
          createCollectionAccount: true,
          invoiceNumber: row.invoice_number,
          amount: row.amount_total,
          issueDate: new Date(row.issue_date),
        },
      }),
    });
  };

  return (
    <Dropdown
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Dropdown.Toggle
        style={{
          height: "40px",
          backgroundColor: "transparent",
          borderColor: "transparent",
          outline: "0",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          paddingLeft: "14px",
        }}
      >
        <PlusCircleIcon stroke="#6938EF" />
      </Dropdown.Toggle>
      <Dropdown.Menu style={styles.noPadding}>
        <Dropdown.Item
          id="dropdown_button_go_to_causation"
          style={styles.newDropdownPadding}
          onClick={() => navigateToCausationInvoice(row)}
        >
          <div style={styles.dropdownItemContainer}>
            <CheckDoneIcon color={"#344054"} height={"20px"} width={"20px"} />
            <div style={styles.dropdownItemTextContainer}>
              <p style={styles.dropdownItemTitle}>Realizar causación</p>
            </div>
          </div>
        </Dropdown.Item>
        <Divider />
        <Dropdown.Item
          id="dropdown_button_mark_as_caused"
          style={styles.newDropdownPadding}
          onClick={() => {
            updateInvoices([row.id]).then(() => {
              refreshTable();
              toast.success("El documento se marcó como causado", toastOptions);
            });
          }}
        >
          <div style={styles.dropdownItemContainer}>
            <CheckCircleIcon color={"#344054"} height={"20px"} width={"20px"} />
            <div style={styles.dropdownItemTextContainer}>
              <p style={styles.dropdownItemTitle}>Marcar como causada</p>
            </div>
          </div>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
