import React, { useState } from "react";
import {
  renderAutocomplete,
  ServicesEmptyState,
} from "screens/ServicesScreenCommons/components";
import { ListPage } from "screens/ListPage";
import { servicesTransactionsColumns, subMenuItems } from "utils";

export const ServicesTransactionsScreen = () => {
  const [query, setQuery] = useState("");
  const getSearchOptions = async (q) => setQuery(q);
  const url = `/transactions?page=1&type=service&text=${query}&per_page=100`;
  const autocomplete = () =>
    renderAutocomplete({
      isMobile: false,
      inputValue: query,
      searchOptions: [],
      onTableSearchSelected: () => {},
      getSearchOptions,
    });
  return (
    <ListPage
      section="services"
      preventRequest={() => query.length > 1 && query.length < 3}
      url={url}
      queries={query ? { query } : {}}
      pageTitle="Lotes"
      tableColumns={servicesTransactionsColumns}
      pageEmptyState={<ServicesEmptyState />}
      subMenuItems={subMenuItems}
      renderAutocomplete={autocomplete}
    />
  );
};
