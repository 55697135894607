import { Box, Stack, Typography } from "@mui/material";
import { EmptyStateSearchIcon } from "assets";
import { ServicesCreateButtons } from "./ServicesCreateButtons";
const styles = {
  box: {
    backgroundColor: "#F9FAFB",
    minHeight: "400px",
    display: "flex",
    justifyContent: "center",
  },
  stack: {
    alignItems: "center",
    padding: "32px 0px",
    justifyContent: "center",
  },
  emptyStateTitle: {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "20px",
    textAlign: "center",
  },
  emptyStateSubtitle: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "center",
  },
};

export const ServicesEmptyState = ({ openManualCreate, openMassiveCreate }) => (
  <Box sx={styles.box}>
    <Stack spacing={2} p={4} sx={styles.stack}>
      <EmptyStateSearchIcon />
      <Box>
        <Typography sx={styles.emptyStateTitle}>
          Todavía no tienes cuentas por pagar
        </Typography>
        <Typography sx={styles.emptyStateSubtitle}>
          Una vez que realices un pago o agendes un servicio, te avisaremos los
          próximos vencimientos
        </Typography>
      </Box>
      <ServicesCreateButtons
        openManualCreate={openManualCreate}
        openMassiveCreate={openMassiveCreate}
      />
    </Stack>
  </Box>
);
