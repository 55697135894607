import React from "react";
import * as ReconciliationsService from "../../../services/api/reconciliations/Reconciliations.service";
import { styles } from "../styles";
import { RadioGroup } from "@mui/material";
import { CustomRadio, StyledFormControlLabel } from "./CustomRadioElements";

export const AccountingRadioGroup = ({
  accountingType,
  setAccountingType,
  selectedMovement,
  setDocumentTypes,
  setLoadingSupplierItems,
  setAccountingAccountOptions,
}) => {
  return (
    <RadioGroup
      row
      aria-labelledby="demo-radio-buttons-group-label"
      value={accountingType}
      name="radio-buttons-group"
      onChange={(e) => {
        setLoadingSupplierItems(true);
        setAccountingType(e.target.value);
        ReconciliationsService.getDocuments(e.target.value).then((res) => {
          setDocumentTypes(res?.response?.status === 400 ? [] : res);
          if (e.target.value === "accountingReceipt") {
            setLoadingSupplierItems(false);
          } else {
            setLoadingSupplierItems(false);
          }
        });
      }}
    >
      {selectedMovement.amount > 0 ? (
        <StyledFormControlLabel
          value="cashRegisterReceipt"
          control={
            <CustomRadio
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: 18,
                },
              }}
            />
          }
          label="Recibo de caja"
          sx={{ marginLeft: "1px" }}
        />
      ) : (
        <StyledFormControlLabel
          value="paymentRecord"
          control={
            <CustomRadio
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: 18,
                },
              }}
            />
          }
          label="Registro de pago"
          sx={{ marginLeft: "1px" }}
        />
      )}
      <StyledFormControlLabel
        value="accountingReceipt"
        control={
          <CustomRadio
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 18,
              },
            }}
          />
        }
        label="Comprobante contable"
        sx={styles.radioInputLabel}
      />
    </RadioGroup>
  );
};
