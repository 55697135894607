import React from "react";
import { Divider, Typography } from "@mui/material";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { ArrowNarrowBottomLeftIcon, ArrowNarrowUpRightIcon } from "assets";
import { NumericFormat } from "react-number-format";
import { StatusPill } from "commons";
import { formatLocaleDate } from "utils";
import { styles } from "../styles";

export const PayanaReceiptItem = ({
  selectedMovement,
  movement,
  setSelectedMovement,
  handleSelectMovement,
}) => {
  const beneficiaryName = {
    invoice: movement?.provider?.name,
    collection: movement?.customer?.name,
    payroll: movement?.employee?.name,
    others: "Comisión Payana",
  };

  const documents = {
    invoice: movement?.invoices,
    collection: movement?.collectionsReceivers,
    payroll: movement?.payrolls,
    others: null,
  };

  const documentNumber = {
    invoice: (document) => {
      return document.invoice_number;
    },
    collection: (document) => {
      return document.concept;
    },
    payroll: (document) => {
      return document.period;
    },
  };

  return (
    <div
      style={
        selectedMovement.id === movement.id
          ? styles.selectedMovement
          : styles.movement
      }
      onClick={() => {
        if (selectedMovement.id === movement.id) {
          setSelectedMovement({});
        } else {
          handleSelectMovement(movement);
        }
      }}
    >
      <div style={styles.movementRow}>
        <div style={styles.movementColumn}>
          <Typography sx={styles.movementText}>
            {formatLocaleDate(movement?.createdAt, "dd/MM/yyyy")}
          </Typography>
          <Typography
            sx={{
              ...styles.movementText,
              fontWeight: "600",
            }}
          >
            {beneficiaryName[movement?.transactionType]}
          </Typography>
          <div style={styles.movementCenteredRow}>
            {movement?.amount > 0 ? (
              <ArrowNarrowBottomLeftIcon width="19px" height="24px" />
            ) : (
              <ArrowNarrowUpRightIcon width="19px" height="24px" />
            )}
            <Typography sx={styles.movementAmount}>
              <NumericFormat
                value={movement?.amount}
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix={"$"}
                decimalScale={2}
              />
            </Typography>
          </div>
        </div>
      </div>
      {documents[movement?.transactionType] && (
        <Typography sx={styles.movementDocumentsTitle}>
          Documentos de la transacción
        </Typography>
      )}
      {documents[movement?.transactionType]?.map((document, index) => {
        return (
          <>
            <div style={styles.movementDocumentContainer}>
              <Typography sx={styles.movementDocumentText}>
                {documentNumber[movement?.transactionType](document)}
              </Typography>
              <Typography sx={styles.movementDocumentText}>
                <NumericFormat
                  value={document.amount_total || document.amount}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"$"}
                  decimalScale={2}
                />
              </Typography>
            </div>
            {index !== documents[movement?.transactionType].length - 1 && (
              <Divider
                sx={{
                  ...styles.reconciledDivider,
                  marginBottom: "6px",
                }}
              />
            )}
          </>
        );
      })}
    </div>
  );
};
