import React, { useContext, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Modal, Spinner } from "react-bootstrap";
import { styles } from "./styles";
import {
  PrimaryButton,
  SecondaryButton,
  WithoutBorderButton,
} from "../../buttons";
import { ExternalLinkIcon, PayanaCar } from "../../../assets";
import { NumericFormat } from "react-number-format";
import { DateDropdown } from "screens/ReconciliationsScreen/components";
import { addMonths, set } from "date-fns";
import { CustomTextField } from "../CollectionObligationModal";
import BankExtractFileUpload from "../BankExtractModal/components/FileUpload";
import { getFromLocalStorage, openInNewTab } from "utils";
import { BANCOLOMBIA_TUTORIAL_LINK } from "constants";
import { createProcess, importBankMovements } from "services";
import { formatLocaleDate, saveInLocalStorage } from "../../../utils";
import { toast } from "react-toastify";
import { toastOptions } from "constants";
import { useNavigate } from "react-router-dom";

export const NewPeriodModal = ({
  visible,
  setIsNewPeriodModalVisible,
  setSelectedBank,
  setSelectedProcessId,
  selectedBank,
  selectedProcess,
  reconciliationsCsvButton,
}) => {
  const [step, setStep] = useState(1);
  const [date, setDate] = useState([
    {
      startDate: addMonths(new Date(), -1),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [selectedDate, setSelectedDate] = useState([
    { startDate: "all", endDate: "all" },
  ]);
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  //const [isBancolombia, setIsBancolombia] = useState(false);
  const [creatingProcess, setCreatingProcess] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleUpload = async () => {
    if (selectedBank?.name === "BANCOLOMBIA") {
      setLoading(true);
      const res = await importBankMovements({
        erpExternalAccount: selectedBank.erp_external_account_code,
        reconciliationProcessId: getFromLocalStorage("reconciliationProcessId"),
        bankId: selectedBank.bank_id,
        bankAlias: selectedBank.bank_alias,
        bankName: "BANCOLOMBIA",
        file: selectedFiles[0],
      });

      const response = JSON.parse(res?.respLambda?.Payload)["lambdaResponse"];

      if (response && response?.Payload?.statusCode !== 200) {
        toast.error(
          "El archivo no coincide con los datos declarados, por favor intenta nuevamente!",
          toastOptions
        );
        setLoading(false);
        return;
      }

      setLoading(false);
      setSelectedFiles(undefined);
      setSelectedBank(null);
      setSelectedProcessId(null);
      navigate("/reconciliations", {
        replace: false,
        state: { importSuccess: true },
      });
      return;
    } else {
      saveInLocalStorage("reconciliationsBankId", selectedBank.id);

      saveInLocalStorage(
        "reconciliationsBankData",
        JSON.stringify({
          erpExternalAccount: selectedBank.erp_external_account_code,
          bankId: selectedBank.erp_external_account_code,
          bankAlias: selectedBank.bank_alias,
        })
      );
      reconciliationsCsvButton.current.click();
      setSelectedBank(null);
      setSelectedProcessId(null);
      navigate("/reconciliations", {
        replace: false,
        state: { importSuccess: true },
      });
      return;
    }
  };

  const content = {
    1: {
      content: (
        <>
          <p
            style={{
              ...styles.title,
              alignSelf: "flex-start",
              textAlign: "flex-start",
              paddingLeft: "0px",
              paddingRight: "0px",
            }}
          >
            Dinos más información para comenzar con el balance
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: "24px",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "12px",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                width: "max-content",
              }}
            >
              <label htmlFor="documentType" style={styles.inputLabel}>
                Elige un período
              </label>
              <DateDropdown
                date={date}
                setDate={setDate}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                defaultText="Elige aquí"
              />
            </div>
          </div>
          <div
            style={{
              alignSelf: "center",
              marginTop: "48px",
              marginBottom: "24px",
            }}
          >
            <PayanaCar />
          </div>
        </>
      ),
    },
    2: {
      content: (
        <>
          <p
            style={{
              ...styles.title,
              alignSelf: "flex-start",
              textAlign: "flex-start",
              paddingLeft: "0px",
              paddingRight: "0px",
            }}
          >
            Importa tus movimientos bancarios
          </p>
          {selectedBank?.name === "BANCOLOMBIA" ? (
            <>
              <BankExtractFileUpload
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
                isInvoiceFile={true}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  marginTop: "12px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "#475467",
                  }}
                >
                  ¿Cómo descargar movimientos bancarios?
                </Typography>
                <WithoutBorderButton
                  text="Ver tutorial"
                  action={() => openInNewTab(BANCOLOMBIA_TUTORIAL_LINK)}
                  width="96px"
                  style={{ height: "20px", paddingRight: "0px" }}
                  contentStyle={{ justifyContent: "flex-end" }}
                  endIcon={<ExternalLinkIcon stroke="#5925DC" />}
                />
              </div>
            </>
          ) : (
            <p
              style={{
                ...styles.subtitle,
                color: "#1D2939",
                marginLeft: "0px",
                alignSelf: "flex-start",
                fontSize: "14px",
              }}
            >
              Presiona el botón Siguiente y carga tu archivo de Excel para
              finalizar con el proceso
            </p>
          )}
        </>
      ),
    },
  };

  const buttons = {
    1: (
      <div style={{ ...styles.row, justifyContent: "flex-end" }}>
        <PrimaryButton
          text={
            creatingProcess ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Siguiente"
            )
          }
          action={async () => {
            // acá tenes que ejecutar el endpoint antes de avanzar al siguiente paso

            setCreatingProcess(true);
            const res = await createProcess({
              reconciliationBankId: selectedBank.id,
              startDate: formatLocaleDate(date[0].startDate, "yyyy-MM-dd"),
              endDate: formatLocaleDate(date[0].endDate, "yyyy-MM-dd"),
              startBalance: selectedProcess?.reconciliationProcess?.end_balance,
              endBalance: selectedProcess?.reconciliationProcess?.end_balance,
            });
            setCreatingProcess(false);
            if (res?.response && res.response.status !== 200) {
              toast.error(
                "El período elegido o el monto declarado son incorrectos",
                toastOptions
              );
              return;
            }
            saveInLocalStorage(
              "reconciliationProcessId",
              res.processResponse.id
            );
            setStep(2);
          }}
          width="50%"
        />
      </div>
    ),
    2: (
      <div style={styles.row}>
        <SecondaryButton
          text="Anterior"
          action={() => {
            setStep(1);
          }}
          width="100%"
        />
        <PrimaryButton
          text={
            selectedBank?.name === "BANCOLOMBIA" ? (
              loading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Comenzar a conciliar"
              )
            ) : (
              "Siguiente"
            )
          }
          action={async () => {
            // acá tenes que ejecutar la request para empezar el período o llevarlo a csvbox, según el caso
            const res = await handleUpload();
            if (res?.response && res.response.status !== 200) {
              toast.error(
                res.response.data.message || "Hubo un error al importar",
                toastOptions
              );
              return;
            }
            setIsNewPeriodModalVisible(false);
          }}
          width="100%"
        />
      </div>
    ),
  };

  return (
    <Modal
      show={visible}
      onHide={() => setIsNewPeriodModalVisible(false)}
      centered
    >
      <Modal.Body>
        <Box style={styles.container}>
          <Typography sx={styles.subtitle}>Paso {step} de 2</Typography>
          <ProgressBar step={step} />
          {content[step]?.content && content[step]?.content}
          {buttons[step]}
        </Box>
      </Modal.Body>
    </Modal>
  );
};

function ProgressBar({ step }) {
  const progress = step === 1 ? 50 : step === 2 ? 100 : 0;

  return (
    <div style={styles.progressContainer}>
      <div style={{ ...styles.progressBar, width: `${progress}%` }} />
    </div>
  );
}
