import React, { useRef, useState } from "react";
import { styles } from "../styles";
import { Divider, Menu, MenuItem } from "@mui/material";
import { SeeMoreButton } from "./SeeMoreButtton";
import {
  ExportReconciliationsFile,
  deleteReconciliationProcess,
} from "services";
import createReconciliationsXLSX from "commons/modals/PickDatesToExportModal/downloadReconciliationsXLSX";
import { formatLocaleDate } from "utils";
import { toast } from "react-toastify";
import { toastOptions } from "constants";

import * as amplitude from "@amplitude/analytics-browser";
import { set } from "date-fns";

export const SeeMoreDropdown = ({
  process,
  bank,
  setIsEndPeriodModalVisible,
  setSelectedProcessId,
  setSelectedBank,
  setIsNewPeriodModalVisible,
  setIsRefreshing,
  setDeleteReconciliationProcessModalIsVisible,
  setReconciliationProcessToDelete,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const closeRef = useRef(null);
  const open = !!anchorEl;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    closeRef?.current?.focus();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const runExport = () => {
    ExportReconciliationsFile(process.id)
      .then((response) =>
        createReconciliationsXLSX({
          response,
          fileName: `Conciliación_${bank.name}_${formatLocaleDate(
            process?.start_date,
            "yyyy-MM-dd"
          )}_${formatLocaleDate(process?.end_date, "yyyy-MM-dd")}_Payana`,
        })
      )
      .then(() => {
        // amplitude.logEvent("DOWNLOAD_CONCILIATION_REPORT", {
        //   user_id: currentUser.id,
        // });
        toast.success("¡Archivo exportado con éxito!", toastOptions);
      })
      .catch((error) => {
        toast.error(
          "Hubo un error exportando el archivo, por favor intente nuevamente.",
          toastOptions
        );
      });
  };

  const deleteReconciliation = async () => {
    const res = await deleteReconciliationProcess(process.id);

    if (res?.response && res.response.status !== 200) {
      if (res.response.status === 400) {
        toast.error(
          "No puedes eliminar una conciliación con movimientos asignados.",
          toastOptions
        );
      } else {
        toast.error("Hubo un error eliminando la conciliación.", toastOptions);
      }
      return;
    }

    setIsRefreshing(true);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <SeeMoreButton open={open} action={handleClick} />
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          slotProps={{
            paper: {
              style: {
                height: 170,
                minHeight: 170,
                maxHeight: 170,
              },
            },
          }}
        >
          {process?.status === "reconciled" ? (
            <MenuItem
              onClick={() => {
                // Lógica para crear un nuevo período
                setSelectedProcessId(process.id);
                setSelectedBank(bank);
                setIsNewPeriodModalVisible(true);
                handleClose();
              }}
            >
              <div style={styles.dropdownItemContainer} type="dropdown">
                <div style={styles.dropdownItemTextContainer}>
                  <p style={styles.dropdownItemText}>Crear nuevo período</p>
                </div>
              </div>
            </MenuItem>
          ) : (
            <MenuItem
              onClick={() => {
                // Lógica para cerrar conciliación
                setSelectedProcessId(process.id);
                setSelectedBank(bank);
                setIsEndPeriodModalVisible(true);
                handleClose();
              }}
            >
              <div style={styles.dropdownItemContainer} type="dropdown">
                <div style={styles.dropdownItemTextContainer}>
                  <p style={styles.dropdownItemText}>Cerrar conciliación</p>
                </div>
              </div>
            </MenuItem>
          )}
          <Divider />
          <MenuItem
            onClick={() => {
              runExport();
              handleClose();
              // setIsEndPeriodModalVisible(true);
            }}
          >
            <div style={styles.dropdownItemContainer} type="dropdown">
              <div
                style={{
                  ...styles.dropdownItemTextContainer,
                  marginTop: "0px",
                }}
              >
                <p style={styles.dropdownItemText}>Descargar conciliación</p>
              </div>
            </div>
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              setDeleteReconciliationProcessModalIsVisible(true);
              setReconciliationProcessToDelete(process.id);
              //deleteReconciliation();
              //handleClose();
              // setIsEndPeriodModalVisible(true);
            }}
          >
            <div style={styles.dropdownItemContainer} type="dropdown">
              <div
                style={{
                  ...styles.dropdownItemTextContainer,
                  marginTop: "0px",
                }}
              >
                <p style={styles.dropdownItemText}>Borrar conciliación</p>
              </div>
            </div>
          </MenuItem>
        </Menu>
      </div>
    </>
  );
};
