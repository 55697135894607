import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import { Typography } from "@mui/material";
import { Submenu, Table, TableEmptyState, TableSkeleton } from "commons";
import { styles } from "./styles";
import { PaymentsContext, UserContext } from "contexts";
import { useAbortableRequest, useChat } from "hooks";
import { UiContext } from "contexts/UiContext";

export const ListPage = ({
  pageEmptyState = null,
  section,
  url = "",
  renderRowEndButtons = () => null,
  queries = {},
  subMenuItems = [],
  tableColumns = () => [],
  renderAutocomplete = () => null,
  pageTitle = "",
  useCheckboxes = false,
  expandLink,
  renderPageFooter = () => null,
  preventRequest = () => false,
  isChecked,
  toggleSelectedRow,
  toggleSelectedAll,
  getTableData = (response) => response?.data,
  buttons,
}) => {
  const [isPageInitialLoading, setIsPageInitialLoading] = useState(true);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const [totals, setTotals] = useState();
  const [tableData, setTableData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const {
    statusSelected,
    perPageSelected,
    setPerPageSelected,
    invoicesScreen,
    tagsSelected,
  } = useContext(PaymentsContext);
  const { currentUser } = useContext(UserContext);
  const providerIdSelected = invoicesScreen?.providerIdSelected?.value;
  const serviceIdSelected = invoicesScreen?.serviceIdSelected?.value;
  const {
    hooks: { setOpenModalKeys, setClosedModalKeys },
  } = useContext(UiContext);
  const pageIndex = invoicesScreen?.pageIndex;

  useChat();

  useEffect(() => {
    if (currentUser.backoffice_user) {
      navigate("/backoffice-receipts", { replace: true });
    }
  }, [currentUser, navigate]);

  const query = new URLSearchParams(queries).toString();

  useAbortableRequest({
    requestConfig: {
      url: `${url}?${query}`,
    },
    preventRequest,
    onInitRequest: () => {
      setLoading(true);
    },
    onAbortRequest: () => {},
    requestThen: (response) => {
      setTableData(getTableData(response));
      setLoading(false);
      setIsPageInitialLoading(false);
    },
  });

  const emptyState = !loading && tableData.length === 0 && query === "";

  return (
    <>
      <div style={styles.container} id="page-wrap">
        <Submenu items={subMenuItems} />
        {isPageInitialLoading ? (
          <div className="table-skeleton-page-initial-loading">
            <TableSkeleton />
          </div>
        ) : emptyState ? (
          pageEmptyState
        ) : (
          <>
            <div className="layout-container">
              <div style={styles.titleContainer}>
                <Typography sx={styles.title} noWrap>
                  {pageTitle}
                </Typography>
                <div
                  style={{ display: "flex", flexDirection: "row", gap: "10px" }}
                >
                  {buttons}
                </div>
              </div>
              <Table
                loading={loading}
                loadingSkeleton={<TableSkeleton />}
                pageNumber={pageIndex}
                columns={tableColumns()}
                data={tableData}
                amountModal
                expandIcon
                expandLink={expandLink}
                setSelectedRows={() => {}}
                setPageIndex={() => {}}
                checkboxVisible={useCheckboxes}
                pageCount={totalPages}
                section={section}
                hasFileIcon
                toggleSelectedRow={toggleSelectedRow}
                isChecked={isChecked}
                renderRowEndButtons={(row) =>
                  renderRowEndButtons(row, setOpenModalKeys, setClosedModalKeys)
                }
                toggleSelectedAll={toggleSelectedAll}
                perPage={perPageSelected}
                setPerPage={setPerPageSelected}
                perPageVisible={true}
                statusSelected={statusSelected}
                providerIdSelected={providerIdSelected}
                serviceIdSelected={serviceIdSelected}
                onClickRow={() => {}}
                renderAutocomplete={renderAutocomplete}
                isCausationAvailable={false}
                tagsSelected={tagsSelected}
                emptyState={
                  <TableEmptyState
                    title={"No se encontraron resultados para esta búsqueda"}
                    subtitle={"Por favor intente nuevamente"}
                  />
                }
              />
            </div>
          </>
        )}
      </div>
      {renderPageFooter(setOpenModalKeys, setClosedModalKeys)}
    </>
  );
};
