import { GetInvoicesNews } from "../services";
import { getFromLocalStorage, saveInLocalStorage } from "./localStorageHandler";

export const getInvoicesNews = async () => {
  const gottenNews = await GetInvoicesNews();
  saveInLocalStorage("gottenNews", gottenNews.news);
};

export const checkInvoicesNews = async () => {
  const gottenNews = await GetInvoicesNews();
  const lastNews = getFromLocalStorage("gottenNews");
  if (gottenNews.news > lastNews) {
    saveInLocalStorage("gottenNews", gottenNews.news);
    return true;
  } else {
    return false;
  }
};
