import { Box, Dialog } from "@mui/material";
import { useContext, useEffect, useReducer, useState } from "react";
import { DebtsSearch } from "./DebtsSearch";
import { DebtsList } from "./DebtsList";
import { ModalHeader } from "./ModalHeader";
import { getPopularServicesSearchOptions } from "./getServicesSearchOptions";
import { searchDebts } from "./searchDebts";
import { createServiceReducer, INITIAL_STATE } from "./createServiceReducer";
import { PageFooter } from "./PageFooter";
import { useSelectedRows } from "screens/ListPage/hooks/useSelectedRows";
import { TransactionsContext, UiContext } from "contexts";
import { CHECKOUT_MODAL } from "commons/modals/keys";
import { TRANSACTION_TYPE } from "constants";
import { SERVICES_ACTIONS } from "./actions";
import { useAbortableRequest } from "hooks/useAbortableRequest";

export const ServicesCreatePaymentModal = ({ visible, onClose }) => {
  const [searchingCompanyServiceByText, setSearchingCompanyServiceByText] =
    useState(false);
  const { createInvoicesPayLaterTransaction } = useContext(TransactionsContext);
  const [state, dispatch] = useReducer(createServiceReducer, INITIAL_STATE);
  const {
    searchOptionSelected,
    servicesSearchOptions,
    identifierValue,
    modality,
    serviceSearchInputText,
  } = state;
  const url = serviceSearchInputText
    ? "/services/companies?q=" + serviceSearchInputText
    : "/services/companies/popular";
  const setSearchOptionSelected = (service) => {
    dispatch(SERVICES_ACTIONS.handleServiceToSearchDebtsSelected(service));
  };
  const setServicesSearchOptions = (services) => {
    dispatch(SERVICES_ACTIONS.handleServicesSearchResults(services));
  };
  const setPopularServicesSearchOptions = (services) => {
    dispatch(SERVICES_ACTIONS.handlePopularServicesSearchResults(services));
  };
  const setIdentifierValue = (identifierValue) => {
    dispatch(SERVICES_ACTIONS.handleIdentifierValueChanged(identifierValue));
  };
  const setModality = (modality) => {
    dispatch(SERVICES_ACTIONS.handleModalityChanged(modality));
  };
  const setServiceSearchInputText = (value) => {
    dispatch(SERVICES_ACTIONS.handleServiceSearchInputTextChanged(value));
  };
  const backToServiceCompanySearch = () => {
    resetSelectedRows();
    dispatch(SERVICES_ACTIONS.handleBackToServiceCompanySearch());
  };
  useAbortableRequest({
    requestConfig: {
      url,
    },
    preventRequest: () =>
      serviceSearchInputText.length > 1 && serviceSearchInputText.length < 3,
    onInitRequest: () => {
      setSearchingCompanyServiceByText(true);
    },
    onAbortRequest: () => {
      setSearchingCompanyServiceByText(false);
    },
    requestThen: (response) => {
      setServicesSearchOptions(response?.services || []);
      setSearchingCompanyServiceByText(false);
    },
  });
  const { selectedRows, isChecked, toggleSelectedRow, resetSelectedRows } =
    useSelectedRows("debtId");
  const {
    hooks: { setOpenModalKeys, setClosedModalKeys },
  } = useContext(UiContext);
  const totalAmount = selectedRows.reduce(
    (acc, row) => acc + row.original.amount,
    0
  );
  const selectedRowsIds = selectedRows.map((row) => row.original.id);
  useEffect(() => {
    if (visible) {
      getPopularServicesSearchOptions().then(setPopularServicesSearchOptions);
    }
  }, [visible]);

  const closeModal = () => {
    onClose();
    setTimeout(() => {
      dispatch(SERVICES_ACTIONS.resetState());
      resetSelectedRows();
    }, 500);
  };
  const searchDebtsHandler = async () => {
    const searchDebtsRequestPayload = {
      companyCode: searchOptionSelected.code,
      modalityId: modality.modalityId,
      modalityName: modality.queryData[0].identifierName,
      modalityValue: identifierValue,
    };
    const debts = await searchDebts(searchDebtsRequestPayload);
    dispatch(SERVICES_ACTIONS.showDebtsList(debts));
  };
  const getPayloadToCreateInvoicesFromDebts = () => ({
    service: searchOptionSelected,
    debts: selectedRows.map((row) => row.original),
  });
  const openCheckoutModal = () => {
    const checkoutModalProps = {
      type: TRANSACTION_TYPE.SERVICE,
      handleClose: () => setClosedModalKeys(CHECKOUT_MODAL),
      totalAmount,
      selectedRowsIds,
      customPayload: getPayloadToCreateInvoicesFromDebts(),
    };
    setOpenModalKeys(CHECKOUT_MODAL, checkoutModalProps);
  };
  const payLater = () => {
    createInvoicesPayLaterTransaction(
      selectedRowsIds,
      TRANSACTION_TYPE.SERVICE,
      getPayloadToCreateInvoicesFromDebts()
    );
  };
  return (
    <Dialog fullScreen open={visible}>
      <div className="modal-header-payana">
        <ModalHeader onClose={closeModal} />
      </div>
      <Box
        sx={{
          padding: 2,
          display: "flex",
          justifyContent: "center",
          paddingBottom: 5,
        }}
      >
        {state.view === "show_debts" && (
          <DebtsList
            debts={state.debts}
            identifierValue={identifierValue}
            service={searchOptionSelected}
            isChecked={isChecked}
            toggleSelectedRow={toggleSelectedRow}
            backToServiceCompanySearch={backToServiceCompanySearch}
          />
        )}
        {state.view === "form" && (
          <DebtsSearch
            options={servicesSearchOptions}
            onChange={setSearchOptionSelected}
            searchOptionSelected={searchOptionSelected}
            setIdentifierValue={setIdentifierValue}
            searchDebts={searchDebtsHandler}
            modality={modality}
            setModality={setModality}
            setServiceSearchInputText={setServiceSearchInputText}
            inputValue={serviceSearchInputText}
            searchingCompanyServiceByText={searchingCompanyServiceByText}
            identifierValue={identifierValue}
          />
        )}
      </Box>
      {state.view === "show_debts" && (
        <PageFooter
          openWompiModal={openCheckoutModal}
          totalAmount={totalAmount}
          selectedRows={selectedRows}
          selectedRowsIds={selectedRowsIds}
          payLater={payLater}
        />
      )}
    </Dialog>
  );
};
