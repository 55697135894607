import React from "react";
import { styles } from "../styles";
import { getTransactionsAccountTypeLabel } from "constants";

export const BeneficiarySection = ({ transaction, beneficiaryType = null }) => {
  const beneficiary = {
    receiver: "cobrador adicional",
    customer: "cliente",
  };

  return (
    <div style={styles.sectionContainer}>
      <p style={styles.sectionTitle}>
        Información del {beneficiary[beneficiaryType] || "destinatario"}
      </p>
      <div style={styles.sectionRow}>
        <p style={styles.title} className="card-subtitle mb-2">
          {beneficiaryType === "customer" ? "Cliente" : "Destinatario"}
        </p>
        <p
          style={styles.transactionHash}
          className="limit-text card-subtitle mb-2"
        >
          {transaction?.beneficiary_name}
        </p>
      </div>
      <div style={styles.sectionRow}>
        <p style={styles.title} className="card-subtitle mb-2">
          {transaction?.beneficiary_document_type?.toUpperCase()}
        </p>
        <p style={styles.transactionHash} className="card-subtitle mb-2">
          {transaction?.beneficiary_document_number}
        </p>
      </div>
      <div style={styles.sectionRow}>
        <p style={styles.title} className="card-subtitle mb-2">
          Mail
        </p>
        <p style={styles.transactionHash} className="card-subtitle mb-2">
          {transaction?.beneficiary_document_email}
        </p>
      </div>
      {transaction.beneficiary_bank_name && (
        <div style={{ ...styles.sectionRow, alignItems: "flex-start" }}>
          <p style={styles.title} className="card-subtitle mb-2">
            Banco destino
          </p>
          <p
            style={{
              ...styles.transactionHash,
              textAlign: "right",
            }}
            className="card-subtitle mb-2"
          >
            {transaction?.beneficiary_bank_name}{" "}
            {getTransactionsAccountTypeLabel(
              transaction.beneficiary_account_type
            )}{" "}
            {transaction?.beneficiary_account_number}
          </p>
        </div>
      )}
    </div>
  );
};
