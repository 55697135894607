import { ANNUAL_TREASURY } from "constants";
import React from "react";

export const TextAddEgress = ({ treasury }) => {
  const text =
    treasury?.featureName === ANNUAL_TREASURY
      ? "$1.224.000 COP anuales"
      : "$120.000 COP mensuales";

  const price =
    treasury?.featureName === ANNUAL_TREASURY
      ? "42.500 anuales"
      : "50.000 mensuales";

  return (
    <p
      style={{
        marginTop: "24px",
        fontStyle: "normal",
        fontSize: "16px",
        lineHeight: "24px",
      }}
    >
      {`Ya tienes tesorería por ${text},`}{" "}
      <strong>{`añade egresos por $${price} más.`}</strong>
    </p>
  );
};
