import { NumericFormat } from "react-number-format";
import stylesCSS from "commons/Table/styles.module.css";
import "./debt-list-item.css";
import { DebtListInnerItem } from "./DebtListInnerItem";
import { CompanyLogo } from "./CompanyLogo";

export const DebtListItem = ({
  debt,
  toggleSelectedRow,
  isChecked,
  serviceCompanyName,
  serviceCompanyLogoUrl,
  identifierValue,
}) => {
  return (
    <button
      className="debt-list-item-button-container"
      onClick={() => toggleSelectedRow(debt)}
    >
      <div className="debt-list-item-container">
        <div className="debt-list-item-inner-container">
          <div style={{ display: "flex" }}>
            <input
              style={{ alignSelf: "center", marginRight: "8px" }}
              className={stylesCSS.input}
              type="checkbox"
              checked={isChecked(debt)}
              onChange={() => {}}
            />
            <CompanyLogo logoUrl={serviceCompanyLogoUrl} />
          </div>
          <div className="debt-list-item-inner-list">
            <DebtListInnerItem>{serviceCompanyName}</DebtListInnerItem>
            <DebtListInnerItem>
              <NumericFormat
                value={parseFloat(debt.amount)}
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
                prefix="$"
                decimalScale={2}
              />
              <span> - Vence {debt.expirationDate}</span>
            </DebtListInnerItem>
            <DebtListInnerItem>{identifierValue}</DebtListInnerItem>
          </div>
        </div>
      </div>
    </button>
  );
};
