import { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "contexts";
import { Spinner } from "react-bootstrap";

export const IsAllowedTo = ({
  children,
  featureKey,
  fallbackUrl = "/payments/invoices",
}) => {
  const [loading, setLoading] = useState(true);
  const { getMe, currentUser, isFeatureEnabled } = useContext(UserContext);
  useEffect(() => {
    if (currentUser?.id) {
      setLoading(false);
    } else {
      getMe().then(() => setLoading(false));
    }
    return () => {
      setLoading(true);
    };
  }, [children]);
  if (loading) {
    return <Spinner animation="border" size="sm" />;
  }
  return isFeatureEnabled(featureKey) ? (
    children
  ) : (
    <Navigate to={fallbackUrl} />
  );
};
