const FailedMessageItem = ({ item }) => {
  const {
    serviceCompanyName,
    modalityDescription,
    modalityValue,
    errorDescription,
  } = item;
  return (
    <div className="list-item-container">
      <div style={{ width: "100px" }}>
        <div className="list-item-header">Servicio</div>
        <div className="limit-text">{serviceCompanyName}</div>
      </div>
      <div style={{ width: "145px" }}>
        <div className="list-item-header">{modalityDescription}</div>
        <div className="limit-text">{modalityValue}</div>
      </div>
      <div className="list-item">
        <div>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.66663 5.33347V8.00012M7.66663 10.6668H7.67329M14.3333 8.00012C14.3333 11.682 11.3485 14.6667 7.66663 14.6667C3.98475 14.6667 1 11.682 1 8.00012C1 4.31825 3.98475 1.3335 7.66663 1.3335C11.3485 1.3335 14.3333 4.31825 14.3333 8.00012Z"
              stroke="#D92D20"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div>{errorDescription}</div>
      </div>
    </div>
  );
};

export default FailedMessageItem;
