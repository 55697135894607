import { Link, useMatch, useResolvedPath } from "react-router-dom";

export const CustomLink = ({ to, children, isNew, ...props }) => {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: false });
  return (
    <li className={isActive ? "active" : ""} {...props}>
      <Link to={to} {...props}>
        {children}
      </Link>
      {isNew && (
        <div
          style={{
            paddingLeft: 4,
            paddingRight: 4,
            paddingTop: 2,
            paddingBottom: 2,
            background: "#A6F4C5",
            borderRadius: 4,
            alignItems: "center",
            gap: 10,
            display: "flex",
            position: "absolute",
            top: "13px",
            marginLeft: "129px",
          }}
        >
          <div
            style={{
              color: "#054F31",
              fontSize: "9px",
              fontWeight: "600",
            }}
          >
            NUEVO
          </div>
        </div>
      )}
    </li>
  );
};
