import React from "react";

export const PayanaIcon = () => {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9874 13.6137L12.4271 8.61745C12.8541 7.1295 12.6512 6.3877 11.8339 6.3877H5.40017L3.70627 12.0648C3.39502 13.1013 3.85841 13.6137 5.07552 13.6137H10.9874Z"
        fill="#5925DC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.1896 0.967773C21.4319 0.967773 21.8939 1.48021 21.5854 2.5167L18.7352 12.0643C18.424 13.1008 17.6591 13.6132 16.421 13.6132H10.9881L12.4278 8.61699C12.8547 7.12903 12.6518 6.38723 11.8346 6.38723H5.40086L6.55572 2.5167C6.86698 1.48021 7.64303 0.967773 8.86991 0.967773H20.1896ZM5.40086 6.38723H4.45275C3.62002 6.38723 3.00005 7.12903 2.57312 8.61699L0.215829 16.7928C-0.211104 18.2894 -0.00116011 19.0327 0.814662 19.0327H8.19229C9.02502 19.0327 9.65485 18.2894 10.0719 16.7928L10.9881 13.6132H5.07621C3.8591 13.6132 3.3957 13.1008 3.70696 12.0643L5.40086 6.38723Z"
        fill="#9B8AFB"
      />
    </svg>
  );
};
