import { Stack } from "@mui/material";
import { DeleteIcon } from "assets";
import {
  CHANGE_SERVICE_COMPANY_ALIAS_MODAL,
  DELETE_ITEM_MODAL,
} from "commons/modals/keys";
import { Dropdown } from "react-bootstrap";
import { ActionsButton } from "screens/InvoicesScreen";
import { deleteServiceFromAgenda } from "services";

export const renderRowEndButtons = (
  row,
  setOpenModalKeys,
  setClosedModalKeys,
  refreshTable
) => {
  const onDelete = () => {
    setOpenModalKeys(DELETE_ITEM_MODAL, {
      itemId: row.original.id,
      action: () => {
        deleteServiceFromAgenda(row.original.id);
      },
      handleClose: () => {
        setClosedModalKeys(DELETE_ITEM_MODAL);
      },
      title: "Eliminar servicio",
      subtitle: (
        <div>
          <span>¿Estas seguro que deseas eliminar el servicio?</span>
          <br />
          <br />
          <span>
            Si lo cancelas dejaremos de mostrarte vencimientos de este servicio.
          </span>
        </div>
      ),
    });
  };
  const onChangeServiceCompanyAlias = () => {
    setOpenModalKeys(CHANGE_SERVICE_COMPANY_ALIAS_MODAL, {
      row,
      onClose: () => {
        refreshTable();
        setClosedModalKeys(CHANGE_SERVICE_COMPANY_ALIAS_MODAL);
      },
    });
  };
  const actions = [
    {
      text: "Eliminar",
      onClick: onDelete,
    },
    {
      text: "Actualizar alias",
      onClick: onChangeServiceCompanyAlias,
    },
  ];
  return (
    <td style={{ backgroundColor: "transparent" }}>
      <Dropdown>
        <ActionsButton />
        <Dropdown.Menu>
          {actions.map((action) => {
            return (
              <Dropdown.Item key={action.text} onClick={action.onClick}>
                <Stack direction="row" gap={1} alignItems="center" height={35}>
                  <DeleteIcon height="18px" width="14px" stroke="#344054" />
                  <div>
                    <span>{action.text}</span>
                  </div>
                </Stack>
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </td>
  );
};
