export const handleFormattedSupplierResponse = (res) => ({
  name: res?.name || "",
  documentType: res?.document_type || "",
  documentNumber: res?.document_number || "",
  email: res?.email || "",
  phone: res?.phone_number || "",
  bankId: res?.bank_id || "",
  accountType: res?.account_type || "",
  accountNumber: res?.account_number || "",
  accountHolderName: res?.account_holder_name || "",
  accountHolderDocumentType: res?.account_holder_document_type || "",
  accountHolderDocumentNumber: res?.account_holder_document_number || "",
  accountHasDifferentHolder: !!res?.has_another_bank_account_holder,
});

export const handleFormattedSupplierForm = (formValues) => ({
  name: formValues?.name || "",
  document_type: formValues?.documentType || "",
  document_number: formValues?.documentNumber || "",
  email: formValues?.email || "",
  phone: formValues?.phone || "",
  bank_id: formValues?.bankId || "",
  account_type: formValues?.accountType || "",
  account_number: formValues?.accountNumber || "",
  account_holder_name: formValues?.accountHolderName || "",
  account_holder_document_type: formValues?.accountHolderDocumentType || "",
  account_holder_document_number: formValues?.accountHolderDocumentNumber || "",
  has_another_bank_account_holder: !!formValues?.accountHasDifferentHolder,
});

export const handleFormattedServiceResponse = (res) => ({
  alias: res?.alias || "",
  service_id: res?.service_id || "",
  referral_code: res?.referral_code || "",
  service: res?.service || "",
});

export const handleFormattedServiceForm = (formValues) => ({
  alias: formValues?.alias || "",
  service_id: formValues?.service_id || "",
  referral_code: formValues?.referral_code || "",
  service: formValues?.service || "",
});
