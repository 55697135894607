import React, { useMemo, useState, useEffect, useContext } from "react";
import { TrackJS } from "trackjs";
import * as amplitude from "@amplitude/analytics-browser";
import {
  Autocomplete,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import {
  Table,
  TableSkeleton,
  PickDatesToExportModal,
  ButtonWithIcon,
  Tooltip,
  NewTransactionsScreenModal,
  TransactionsFeedbackModal,
} from "../../commons";
import {
  transactionsColumns,
  formatLocaleDate,
  downloadFile,
  getFromLocalStorage,
  saveInLocalStorage,
  openInNewTab,
} from "../../utils";
import { styles } from "./styles";
import {
  GetTotalTransactionsV2,
  SearchTransactionBeneficiary,
} from "../../services";
import { TransactionsContext, UserContext } from "../../contexts";
import { TableEmptyState } from "../../commons/Layout/EmptyState";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import {
  ArrowRightWithoutStick,
  DownloadFileIcon2,
  DownloadFileIcon3,
  DownloadFileIcon4,
  SearchIcon,
} from "../../assets";
import { useChat } from "../../hooks";
import {
  DateDropdown,
  IntroBanner,
  TransactionsScreenIntro,
  TypeDropdown,
} from "./components";
import { addMonths, format } from "date-fns";
import { transactionEventType } from "constants";
import { TRANSACTIONS_FEEDBACK_LINK } from "constants";

export const TransactionsScreen = (props) => {
  const navigate = useNavigate();
  const [totals, setTotal] = useState();
  const [totalPages, setTotalPages] = useState(1);
  const { transactions, getTransactionsV2, transactionsScreen } =
    useContext(TransactionsContext);
  const { currentUser, canDisplayAssistedEgress } = useContext(UserContext);

  const pageIndex = transactionsScreen?.pageIndex;
  const setPageIndex = transactionsScreen?.setPageIndex;

  const beneficiaryIdSelected =
    transactionsScreen?.beneficiaryIdSelected?.value;
  const setBeneficiaryIdSelected =
    transactionsScreen?.beneficiaryIdSelected?.setter;

  const [beneficiaryTypeSelected, setBeneficiaryTypeSelected] =
    useState("provider");

  const [searchOptions, setSearchOptions] = useState([]);
  const [date, setDate] = useState([
    {
      startDate: addMonths(new Date(), -1),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [type, setType] = useState([
    { id: "invoice", name: "Proveedores", enabled: false },
    { id: "payroll", name: "Nómina", enabled: false },
    { id: "collection", name: "Cobros", enabled: false },
  ]);
  const [selectedDate, setSelectedDate] = useState([
    { startDate: "all", endDate: "all" },
  ]);
  const [selectedType, setSelectedType] = useState(false);

  const startDate = date.at(0).startDate
    ? format(date.at(0).startDate, "yyyy-MM-dd")
    : "all";
  const endDate = date.at(0).endDate
    ? format(date.at(0).endDate, "yyyy-MM-dd")
    : "all";

  const [isExportModalVisible, setIsExportModalVisible] = useState(false);
  const [isPageInitialLoading, setIsPageInitialLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [showIntro, setShowIntro] = useState(false);

  const [showFeedbackModal, setShowFeedbackModal] = useState(
    getFromLocalStorage("showFeedbackModal") || "true"
  );

  const isFeedbackModalVisible = showFeedbackModal !== "false";

  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });

  const [transactionsFormatted, setTransactionsFormatted] = useState([]);

  useChat();

  const GroupHeader = styled("div")(({ theme }) => ({
    position: "sticky",
    top: "-8px",
    padding: "4px 10px",
    color: "#475467",
    fontWeight: "600",
    fontSize: "14px",
    backgroundColor: "white",
  }));

  const GroupItems = styled("ul")({
    padding: 0,
  });

  const searchGroupTitle = {
    provider: "Proveedores",
    employee: "Empleados",
    customer: "Clientes",
    receiver: "Cobradores adicionales",
  };

  const refreshTransactionsTable = async () => {
    setLoading(true);
    try {
      await getTransactionsV2(
        pageIndex,
        beneficiaryIdSelected,
        beneficiaryTypeSelected,
        startDate,
        endDate,
        selectedType
          ? type
              .filter((currentType) => currentType.enabled)
              .map((currentType) => currentType.id)
              .join(",")
          : "invoice,payroll,collection"
      );

      const totalTransactions = await GetTotalTransactionsV2({
        beneficiaryIdSelected,
        beneficiaryTypeSelected,
        startDate,
        endDate,
        transactionType: selectedType
          ? type
              .filter((currentType) => currentType.enabled)
              .map((currentType) => currentType.id)
              .join(",")
          : "invoice,payroll,collection",
      });
      setTotal(totalTransactions);
      transactionsScreen?.beneficiaryFilter.value.id &&
        getSearchOptions(transactionsScreen?.beneficiaryFilter.value.name);
    } catch (error) {
      TrackJS.console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const batchOperationType = {
    invoice: "invoices",
    payroll: "payrolls",
    collection: "collections",
  };

  const formatTransactions = () => {
    if (transactions?.length === 0) {
      setTransactionsFormatted([]);
      return;
    }

    const firstTransaction = transactions?.at(0);
    let date = formatLocaleDate(firstTransaction?.date_hour, "dd/MM/yyyy");
    let batchNumber = firstTransaction.batch_number;

    const transactionsTmp = [];
    transactionsTmp.push({
      type: "date",
      date: firstTransaction?.date_hour,
    });
    transactionsTmp.push({
      type: "section",
      operation_type: batchOperationType[firstTransaction.operation_type],
      total_amount:
        firstTransaction.operation_type === "collection" &&
        firstTransaction.beneficiary_type === "main"
          ? firstTransaction.total_amount
          : firstTransaction.batch_total_amount,
      date_hour: firstTransaction.date_hour,
      source_of_funds:
        firstTransaction.operation_type === "collection" &&
        firstTransaction.beneficiary_type === "main"
          ? {
              bankName: firstTransaction.beneficiary_bank_name,
              accountType: firstTransaction.beneficiary_account_type,
              accountNumber: firstTransaction.beneficiary_account_number,
              type: "bank_account",
            }
          : firstTransaction.source_of_funds,
    });
    transactions.map((transaction) => {
      if (formatLocaleDate(transaction?.date_hour, "dd/MM/yyyy") !== date) {
        date = formatLocaleDate(transaction.date_hour, "dd/MM/yyyy");
        transactionsTmp.push({
          type: "date",
          date: transaction.date_hour,
        });
      }
      if (transaction.batch_number !== batchNumber) {
        batchNumber = transaction.batch_number;
        transactionsTmp.push({
          type: "section",
          operation_type: batchOperationType[transaction.operation_type],
          total_amount:
            transaction.operation_type === "collection" &&
            transaction.beneficiary_type === "main"
              ? transaction.total_amount
              : transaction.batch_total_amount,
          date_hour: transaction.date_hour,
          source_of_funds:
            transaction.operation_type === "collection" &&
            transaction.beneficiary_type === "main"
              ? {
                  bankName: transaction.beneficiary_bank_name,
                  accountType: transaction.beneficiary_account_type,
                  accountNumber: transaction.beneficiary_account_number,
                  type: "bank_account",
                }
              : transaction.source_of_funds,
        });
      }
      if (
        transaction.operation_type === "collection" &&
        transaction.beneficiary_type === "main"
      ) {
        return null;
      }
      transactionsTmp.push({ ...transaction, type: "row" });
    });
    setTransactionsFormatted(transactionsTmp);
  };

  useEffect(() => {
    formatTransactions();
  }, [transactions]);

  useEffect(() => {
    refreshTransactionsTable().finally(() => {
      setIsPageInitialLoading(false);
    });
  }, []);

  useEffect(() => {
    if (!isPageInitialLoading) {
      refreshTransactionsTable();
    }
  }, [pageIndex, beneficiaryIdSelected, selectedDate, selectedType]);

  useEffect(() => {
    if (totals) {
      if (totals.total > 5) {
        setTotalPages(Math.ceil(totals.total / 5));
      } else {
        setTotalPages(1);
      }
    }
  }, [totals]);

  const columns = useMemo(
    () => transactionsColumns(canDisplayAssistedEgress),
    [canDisplayAssistedEgress]
  );

  const openDetailsModal = (row) => {
    amplitude.logEvent("VIEW_TRANSACTIONS_DETAILS", {
      user_id: currentUser.id,
      transaction_id: row.transaction_id,
      receipt_id: row.id,
      module: transactionEventType[row.operation_type],
    });
    navigate(`/transactions/${row.id}`, {
      replace: true,
      ...(row.operation_type === "payana_commission" && {
        state: { isPayanaCommission: true },
      }),
      ...(row.operation_type === "collection" && {
        state: { beneficiaryType: row.beneficiary_type },
      }),
    });
  };

  const getSearchOptions = (query) => {
    SearchTransactionBeneficiary(query).then((res) => {
      setSearchOptions(res);
    });
  };

  const handleSearchChange = (beneficiary) => {
    if (beneficiary) {
      amplitude.logEvent("USE_SEARCH", {
        user_id: currentUser.id,
        filter_type: beneficiary.type,
        filter_value: beneficiary.name,
      });
      setBeneficiaryIdSelected(beneficiary.id);
      setBeneficiaryTypeSelected(beneficiary.type);
      transactionsScreen?.beneficiaryFilter?.setter(beneficiary);
    } else {
      setBeneficiaryIdSelected("all");
      setBeneficiaryTypeSelected("provider");
      transactionsScreen?.beneficiaryFilter?.setter({});
    }
    setPageIndex(1);
  };

  const renderAutocomplete = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "12px",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
            height: "-webkit-fill-available",
          }}
        >
          <Autocomplete
            options={searchOptions}
            groupBy={(option) => option.type}
            size="small"
            sx={{ width: isMobile ? "100%" : "35%", minWidth: "164px" }}
            freeSolo
            getOptionLabel={(option) => {
              return option?.name || "";
            }}
            onInputChange={(event) => {
              if (event?.target?.value?.length >= 3) {
                getSearchOptions(event.target.value);
              }
            }}
            onChange={(event, option) => {
              if (option?.id) {
                handleSearchChange(option);
              } else {
                handleSearchChange();
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  style: { fontSize: 14 },
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                placeholder={"Buscar por tercero"}
              />
            )}
            renderGroup={(params) => (
              <li key={params.key}>
                <GroupHeader>{searchGroupTitle[params.group]}</GroupHeader>
                <GroupItems>{params.children}</GroupItems>
              </li>
            )}
            value={
              transactionsScreen?.beneficiaryFilter.value || {
                name: "",
              }
            }
          />
          <DateDropdown
            date={date}
            setDate={setDate}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            setPageIndex={setPageIndex}
          />
          <TypeDropdown
            type={type}
            setType={setType}
            selectedType={selectedType}
            setSelectedType={setSelectedType}
            setPageIndex={setPageIndex}
          />
        </div>
        <ButtonWithIcon
          action={() => setIsExportModalVisible(true)}
          buttonLabel={"Descargar conciliación"}
          icon={<DownloadFileIcon2 stroke="#FFFFFF" width="16" height="20" />}
          width={"210px"}
        />
      </div>
    );
  };

  const renderButtons = (row) => {
    if (row.original.type === "date" || row.original.type === "section") {
      return null;
    }

    return (
      <>
        {row.original.receipt_file_path ? (
          <Tooltip title="Descargar soporte">
            <IconButton
              onClick={() => downloadFile(row.original.receipt_file_path)}
              className={"firstBatchRowDownloadButton"}
            >
              <DownloadFileIcon3 size="20px" />
            </IconButton>
          </Tooltip>
        ) : (
          <IconButton
            onClick={() => {}}
            sx={{ backgroundColor: "transparent" }}
            disabled
          >
            <DownloadFileIcon3 size="20px" stroke="#FFFFFF" />
          </IconButton>
        )}
        <Tooltip title="Ver detalle">
          <IconButton
            onClick={() => openDetailsModal(row.original)}
            className={"firstBatchRowDetailsButton"}
          >
            <ArrowRightWithoutStick
              stroke={"#475467"}
              width={"20px"}
              height={"20px"}
            />
          </IconButton>
        </Tooltip>
      </>
    );
  };

  const renderRowEndButtons = (row) => {
    if (isMobile) {
      return <>{renderButtons(row)}</>;
    } else {
      return (
        <td style={{ backgroundColor: "transparent" }}>{renderButtons(row)}</td>
      );
    }
  };

  return (
    <>
      <TransactionsFeedbackModal
        visible={isFeedbackModalVisible && transactions?.length > 0}
        handleConfirm={() => {
          saveInLocalStorage("showFeedbackModal", "false");
          setShowFeedbackModal("false");
          openInNewTab(TRANSACTIONS_FEEDBACK_LINK);
        }}
        handleSkip={() => {
          saveInLocalStorage("showFeedbackModal", "false");
          setShowFeedbackModal("false");
        }}
      />
      <PickDatesToExportModal
        visible={isExportModalVisible}
        handleClose={() => setIsExportModalVisible(false)}
      />
      <TransactionsScreenIntro
        enabled={
          !isPageInitialLoading &&
          !loading &&
          showIntro &&
          transactions?.length > 0
        }
        onComplete={() => {
          setShowIntro(false);
        }}
        onExit={() => {
          setShowIntro(false);
        }}
      />
      <div className="layout-container">
        <div style={styles.titleContainer}>
          <Typography sx={styles.title} noWrap>
            Transacciones
          </Typography>
        </div>
        {!isPageInitialLoading && !loading && transactions?.length > 0 && (
          <IntroBanner
            setShowIntro={setShowIntro}
            setShowFeedback={() => openInNewTab(TRANSACTIONS_FEEDBACK_LINK)}
          />
        )}
        {isPageInitialLoading ? (
          <div className="table-skeleton-page-initial-loading">
            <TableSkeleton />
          </div>
        ) : (
          <Table
            loading={loading}
            loadingSkeleton={<TableSkeleton />}
            columns={columns}
            pageNumber={pageIndex}
            clearSearchText={() => {}}
            data={transactionsFormatted}
            setPageIndex={setPageIndex}
            section="transactions"
            moneyIcon
            expandIcon
            expandAction={openDetailsModal}
            checkboxVisible={false}
            pageCount={totalPages}
            renderAutocomplete={renderAutocomplete}
            emptyState={
              <TableEmptyState
                title={"No se encontraron resultados"}
                subtitle={"Por favor revisa tus filtros de búsqueda."}
              />
            }
            renderRowEndButtons={renderRowEndButtons}
          />
        )}
      </div>
    </>
  );
};
