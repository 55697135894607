import { useState } from "react";
import { PrimaryButton } from "commons/buttons";

export const SearchButton = ({ onClick, disabled }) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <PrimaryButton
      text="Buscar"
      action={async () => {
        try {
          setIsLoading(true);
          await onClick();
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      }}
      width="150px"
      loading={isLoading}
      isDisable={disabled}
      style={{
        alignSelf: "flex-end",
      }}
    />
  );
};
