import React, { useContext, useEffect, useState } from "react";
import * as amplitude from "@amplitude/analytics-browser";
import {
  Box,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { styles } from "../styles";
import {
  ExternalLinkIcon,
  PayanaCar,
  SiigoLogo,
  UpdateIcon,
} from "../../../assets";
import {
  PrimaryButton,
  SecondaryButton,
  WithoutBorderButton,
} from "commons/buttons";
import {
  BankEntitySelector,
  ButtonWithIcon,
  CustomSkeleton,
  CustomTextField,
  NumberCircle,
  ValidationMessage,
} from "commons";
import {
  CustomStepsRadio,
  StyledFormControlLabel,
} from "./CustomRadioElements";
import {
  formatLocaleDate,
  getFromLocalStorage,
  openInNewTab,
  saveInLocalStorage,
} from "utils";
import { Spinner } from "react-bootstrap";
import {
  checkSiigoCredentials,
  createProcess,
  getAccountingAccounts,
  getReconciliationsToken,
  importBankMovements,
  setReconciliationBankData,
} from "services";
import { PaymentsContext, ReconciliationsContext, UserContext } from "contexts";
import { useForm, useWatch } from "react-hook-form";
import { addMonths } from "date-fns";
import { DateDropdown } from "./DateDropdown";
import { NumericFormat } from "react-number-format";
import BankExtractFileUpload from "commons/modals/BankExtractModal/components/FileUpload";
import { BANCOLOMBIA_TUTORIAL_LINK } from "constants";
import { toast } from "react-toastify";
import { toastOptions } from "constants";
import { useNavigate } from "react-router-dom";
import AccountingAccountSelect from "./AccountingAccountSelect";

export const ReconciliationsEmptyState = ({
  setIsSiigoModalVisible,
  setIsBankExtractModalVisible,
  syncCS,
  checkCredentials,
  reconciliationsCsvButton,
  setShowNewReconciliation,
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [syncingSiigo, setSyncingSiigo] = useState(false);
  const [lastSyncDate, setLastSyncDate] = useState(null);

  const navigate = useNavigate();

  const syncSiigo = () => {
    setSyncingSiigo(true);
    checkSiigoCredentials().then((res) => {
      setLastSyncDate(res?.syncValidation?.lastSyncCausationAt);
      setSyncingSiigo(false);
    });
  };

  const steps = [
    {
      stepNumber: 1,
      label: "Sincroniza con Siigo",
      numberIcon: <NumberCircle number={1} />,
    },
    {
      stepNumber: 2,
      label: "Elige el banco a conciliar",
      numberIcon: <NumberCircle number={2} />,
    },
    {
      stepNumber: 3,
      label: "Dinos información sobre tu balance",
      numberIcon: <NumberCircle number={3} />,
    },
    {
      stepNumber: 4,
      label: "Importa tus movimientos bancarios",
      numberIcon: <NumberCircle number={4} />,
    },
  ];

  const goToStep = (stepNumber) => {
    setCurrentStep(stepNumber);
    if (!completedSteps.includes(stepNumber)) {
      setCompletedSteps([...completedSteps, stepNumber]);
    }
  };

  const isStepCompleted = (stepNumber) => completedSteps.includes(stepNumber);

  const { currentUser } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [requestInProcess, setRequestInProcess] = useState(false);
  const [accountingAccountOptions, setAccountingAccountOptions] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [initialAmount, setInitialAmount] = useState(undefined);
  //const [finalAmount, setFinalAmount] = useState(undefined);
  const [isBancolombia, setIsBancolombia] = useState(false);

  const [date, setDate] = useState([
    {
      startDate: addMonths(new Date(), -1),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [selectedDate, setSelectedDate] = useState([
    { startDate: "all", endDate: "all" },
  ]);

  const { register, formState, control, setValue } = useForm({
    defaultValues: {
      accountingAccount: "",
      bankId: "",
      alias: "",
      initialAmount: 0,
      //finalAmount: 0,
    },
    mode: "onBlur",
  });

  const formValues = useWatch({ control });
  const { getAllBanks, allBanks } = useContext(PaymentsContext);
  const { banks } = useContext(ReconciliationsContext);

  useEffect(() => {
    if (currentStep === 1) {
      checkCredentials();
    }
  }, []);

  const handleAccountChange = (newValue) => {
    setValue("accountingAccount", newValue, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  useEffect(() => {
    if (currentStep === 2) {
      getReconciliationsToken().then(() => {
        getAllBanks();
        setLoading(false);
      });
    }
  }, [currentStep]);

  useEffect(() => {
    if (syncCS?.siigo && currentStep === 1) {
      if (!completedSteps.includes(currentStep)) {
        setCompletedSteps([...completedSteps, currentStep]);
      }
      setCurrentStep(currentStep + 1);
    }
  }, [syncCS]);

  const renderStepContent = {
    1: (
      <>
        <Typography
          sx={{ color: "#1D2939", fontSize: "16px", fontWeight: "500" }}
        >
          Sincroniza con Siigo
        </Typography>
        <div
          style={{
            background: "#FFFFFF",
            padding: "4px 6px 4px 14px",
            borderRadius: "16px",
            border: "1px solid #EAECF0",
            display: "flex",
            flexDirection: "row",
            gap: "8px",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            height: "40px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "12px",
            }}
          >
            <div
              style={{
                width: "24px",
                height: "24px",
                marginRight: "8px",
              }}
            >
              <SiigoLogo style={{ marginBottom: "2px" }} />
            </div>
            {lastSyncDate && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "4px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: "12px",
                    lineHeight: "20px",
                    textAlign: "left",
                    color: "#667085",
                    marginTop: "2px",
                  }}
                >
                  Última actualización
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "20px",
                    textAlign: "left",
                    color: "#1D2939",
                    marginTop: "2px",
                  }}
                >
                  {formatLocaleDate(lastSyncDate, "d MMM yyyy, HH:mm")}
                </Typography>
              </div>
            )}
          </div>
          {syncingSiigo ? (
            <Spinner
              style={{ marginRight: "8px" }}
              animation="border"
              size="sm"
              variant="primary"
            />
          ) : (
            <ButtonWithIcon
              buttonLabel={syncCS?.siigo ? "Actualizar" : "Sincronizar"}
              endIcon={<UpdateIcon />}
              width="fit-content"
              style={{
                height: "28px",
                borderRadius: "16px",
                padding: "4px 12px 4px 12px",
              }}
              action={() => {
                syncCS?.siigo ? syncSiigo() : setIsSiigoModalVisible(true);
              }}
            />
          )}
        </div>
      </>
    ),
    2: (
      <form style={{ height: "100%" }}>
        <Box sx={styles.container}>
          <p
            style={{
              ...styles.subtitle,
              color: "#101828",
              marginLeft: "0px",
              alignSelf: "flex-start",
              fontSize: "16px",
            }}
          >
            Elige el banco a conciliar
          </p>
          <div
            style={{
              backgroundColor: "#FFFFFF",
              borderRadius: "8px",
              gap: "12px",
              width: "100%",
              alignSelf: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: loading ? "center" : "normal",
            }}
          >
            {loading ? (
              <div
                className="table-skeleton-page-initial-loading"
                style={{ width: "100%", marginBottom: "16px", padding: "0px" }}
              >
                <CustomSkeleton rows={3} />
              </div>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: "24px",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      width: "100%",
                    }}
                  >
                    <label
                      htmlFor="accountingAccount"
                      style={styles.inputLabel}
                    >
                      Elige la cuenta contable en Siigo
                    </label>

                    <AccountingAccountSelect
                      value={formValues.accountingAccount || ""}
                      onAccountChange={handleAccountChange}
                    />
                    <ValidationMessage
                      errors={formState.errors}
                      field="accountingAccount"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      width: "100%",
                    }}
                  >
                    <label htmlFor="documentType" style={styles.inputLabel}>
                      Elige tu banco
                    </label>
                    <BankEntitySelector
                      bankSelectedId={formValues.bankId}
                      validationProps={register("bankId", { required: true })}
                    />
                    <ValidationMessage
                      errors={formState.errors}
                      field="bankId"
                    />
                  </div>
                </div>
                <label htmlFor="documentType" style={styles.inputLabel}>
                  Alias
                </label>
                <TextField
                  id="accountNumber"
                  size="small"
                  variant="outlined"
                  placeholder="Ejemplo: CC 2521253"
                  value={formValues.alias}
                  {...register("alias", { required: true })}
                  InputProps={{
                    style: { fontSize: 14, backgroundColor: "#FFFFFF" },
                  }}
                />
                <ValidationMessage errors={formState.errors} field="alias" />
              </>
            )}
          </div>
        </Box>
      </form>
    ),
    3: (
      <form style={{ height: "100%" }}>
        <Box sx={styles.container}>
          <p
            style={{
              ...styles.subtitle,
              color: "#101828",
              marginLeft: "0px",
              alignSelf: "flex-start",
              fontSize: "16px",
            }}
          >
            Dinos más información para comenzar con el balance
          </p>
          <div
            style={{
              backgroundColor: "#FFFFFF",
              borderRadius: "8px",
              gap: "12px",
              width: "100%",
              alignSelf: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: loading ? "center" : "normal",
            }}
          >
            {loading ? (
              <div
                className="table-skeleton-page-initial-loading"
                style={{ width: "100%", marginBottom: "16px", padding: "0px" }}
              >
                <CustomSkeleton rows={3} />
              </div>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    width: "100%",
                  }}
                >
                  <label htmlFor="documentType" style={styles.inputLabel}>
                    Elige un período
                  </label>
                  <DateDropdown
                    date={date}
                    setDate={setDate}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    defaultText="Elige aquí"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: "24px",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      width: "100%",
                    }}
                  >
                    <label
                      htmlFor="accountingAccount"
                      style={styles.inputLabel}
                    >
                      Saldo bancario inicial
                    </label>
                    <NumericFormat
                      id="initialAmount"
                      name="initialAmount"
                      thousandSeparator="."
                      decimalSeparator=","
                      allowNegative={true}
                      isNumericString={true}
                      value={initialAmount}
                      {...register("initialAmount", { required: true })}
                      customInput={CustomTextField}
                      sx={{ width: "100%" }}
                      onValueChange={(values, sourceInfo) => {
                        setInitialAmount(values.floatValue);
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </Box>
      </form>
    ),
    4: (
      <Box sx={styles.container}>
        <p
          style={{
            ...styles.subtitle,
            color: "#101828",
            marginLeft: "0px",
            alignSelf: "flex-start",
            fontSize: "16px",
          }}
        >
          Importa tus movimientos bancarios
        </p>
        {isBancolombia ? (
          <>
            <BankExtractFileUpload
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              isInvoiceFile={true}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                marginTop: "12px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "600",
                  color: "#475467",
                }}
              >
                ¿Cómo descargar movimientos bancarios?
              </Typography>
              <WithoutBorderButton
                text="Ver tutorial"
                action={() => openInNewTab(BANCOLOMBIA_TUTORIAL_LINK)}
                width="96px"
                style={{ height: "20px", paddingRight: "0px" }}
                contentStyle={{ justifyContent: "flex-end" }}
                endIcon={<ExternalLinkIcon stroke="#5925DC" />}
              />
            </div>
          </>
        ) : (
          <p
            style={{
              ...styles.subtitle,
              color: "#1D2939",
              marginLeft: "0px",
              alignSelf: "flex-start",
              fontSize: "14px",
            }}
          >
            Presiona el botón Siguiente y carga tu archivo de Excel para
            finalizar con el proceso
          </p>
        )}
      </Box>
    ),
  };

  const nextStep = {
    1: async () => {
      return;
    },
    2: async () => {
      return setReconciliationBankData({
        erpExternalAccountCode: formValues.accountingAccount,
        bankId: formValues.bankId,
        bankAlias: formValues.alias,
      });
    },
    3: async () => {
      return createProcess({
        reconciliationBankId: getFromLocalStorage("reconciliationsBankId"),
        startDate: formatLocaleDate(date[0].startDate, "yyyy-MM-dd"),
        endDate: formatLocaleDate(date[0].endDate, "yyyy-MM-dd"),
        startBalance: initialAmount,
        endBalance: initialAmount,
      });
    },
    4: async () => {
      amplitude.logEvent("START_BANK_MOVEMENTS_IMPORT", {
        user_id: currentUser.id,
      });

      const bankData = JSON.parse(
        getFromLocalStorage("reconciliationsBankData")
      );

      if (isBancolombia) {
        return importBankMovements({
          erpExternalAccount: bankData.erpExternalAccount,
          reconciliationProcessId: getFromLocalStorage(
            "reconciliationProcessId"
          ),
          bankId: bankData.bankId,
          bankAlias: bankData.bankAlias,
          bankName: "BANCOLOMBIA",
          file: selectedFiles[0],
        });
      } else {
        setIsBankExtractModalVisible(false);
        setRequestInProcess(false);
        reconciliationsCsvButton.current.click();
        return;
      }
    },
  };

  const handleSubmit = async () => {
    setLoading(true);
    const res = await nextStep[currentStep]();

    if (res?.response && res.response.status !== 200) {
      if (currentStep === 2) {
        toast.error(
          "Tienes una conciliación abierta para este mismo banco, revisa en la página principal.",
          toastOptions
        );
      } else if (currentStep === 3) {
        toast.error(
          "La fecha de inicio del nuevo proceso de conciliación debe ser igual a la fecha de fin del proceso de conciliación anterior.",
          toastOptions
        );
      } else {
        toast.error(
          "Hubo un error, por favor intenta nuevamente!",
          toastOptions
        );
      }
      setLoading(false);
      return;
    }

    if (currentStep === 2) {
      saveInLocalStorage("reconciliationsBankId", res.id);

      saveInLocalStorage(
        "reconciliationsBankData",
        JSON.stringify({
          erpExternalAccount: formValues.accountingAccount,
          bankId: formValues.bankId,
          bankAlias: formValues.alias,
        })
      );

      setIsBancolombia(
        allBanks.find((bank) => bank.id === formValues.bankId).name ===
          "BANCOLOMBIA"
      );
    }

    if (currentStep === 3) {
      saveInLocalStorage("reconciliationProcessId", res.processResponse.id);
    }

    if (currentStep === 4 && isBancolombia) {
      const response = res.respLambda.Payload.lambdaResponse;

      if (response && response?.statusCode !== 200) {
        toast.error(
          "Hubo un error, por favor intenta nuevamente!",
          toastOptions
        );
      } else {
        setShowNewReconciliation(false);
      }

      setRequestInProcess(false);
      setLoading(false);
      setSelectedFiles(undefined);
      navigate("/reconciliations", {
        replace: false,
        state: { importSuccess: true },
      });
      setRequestInProcess(false);
      return;
    }

    if (!completedSteps.includes(currentStep)) {
      setCompletedSteps([...completedSteps, currentStep]); // Marca el paso como completado
    }
    setCurrentStep(currentStep + 1);
    setLoading(false);
  };

  return (
    <>
      <div style={styles.titleContainer}>
        <Typography sx={styles.title} noWrap>
          Conciliaciones
        </Typography>
      </div>
      <Box sx={styles.emptyStateBox}>
        <Stack
          sx={{
            ...styles.emptyStateStack,
            backgroundColor: "#FCFCFD",
            maxWidth: "543px",
          }}
        >
          <Stack orientation="vertical" size="large" spacing={2}>
            <Typography
              sx={{ color: "#1D2939", fontSize: "16px", fontWeight: "500" }}
            >
              Comienza a conciliar tus movimientos bancarios
            </Typography>
            {steps.map((step) => (
              <div
                key={step.stepNumber}
                className="step"
                style={{
                  border:
                    step.stepNumber === currentStep
                      ? "2px solid #7F56D9"
                      : "1px solid #EAECF0",
                  borderRadius: "8px",
                  backgroundColor: "#FFFFFF",
                  padding: "16px",
                  width: "100%",
                  height: "70px",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "12px",
                  }}
                >
                  {step.numberIcon}
                  <Typography
                    sx={{
                      color: "#344054",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    {step.label}
                  </Typography>
                </div>
                <StyledFormControlLabel
                  value={step.stepNumber}
                  control={
                    <CustomStepsRadio
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 18,
                        },
                      }}
                    />
                  }
                  sx={{ alignSelf: "flex-end", marginRight: "0px" }}
                  checked={isStepCompleted(step.stepNumber)}
                  onChange={() => goToStep(step.stepNumber)}
                />
              </div>
            ))}
            <div style={{ alignSelf: "center", marginTop: "24px" }}>
              <PayanaCar />
            </div>
          </Stack>
        </Stack>
        <Stack
          sx={{
            ...styles.emptyStateStack,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack
            orientation="vertical"
            size="large"
            spacing={2}
            sx={{
              width: "100%",
              maxWidth: "535px",
              height: "235px",
              maxHeight: "235px",
            }}
          >
            {renderStepContent[currentStep]}
            <Stack direction="row" spacing={1} pt={4} justifyContent="flex-end">
              {currentStep !== 1 && (
                <SecondaryButton
                  text="Anterior"
                  action={() => {
                    setCurrentStep(currentStep - 1);
                  }}
                  width="185px"
                />
              )}
              <PrimaryButton
                isDisable={
                  loading ||
                  (currentStep === 4 &&
                    isBancolombia &&
                    (!selectedFiles ||
                      (selectedFiles && selectedFiles.length === 0)))
                }
                text={
                  loading ? (
                    <Spinner animation="border" size="sm" variant="secondary" />
                  ) : currentStep === 4 && isBancolombia ? (
                    "Finalizar"
                  ) : (
                    "Siguiente"
                  )
                }
                action={async () => {
                  handleSubmit();
                }}
                width="185px"
              />
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};
