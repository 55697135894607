export const getPayloadToCreateInvoicesFromDebts = (
  debts,
  selectedRowsIds
) => ({
  debts: debts
    .map((companyDebts) => {
      return {
        serviceCompany: companyDebts.service,
        debts: companyDebts.debts.filter((debt) =>
          selectedRowsIds.includes(debt.debtId)
        ),
      };
    })
    .filter((companyDebts) => companyDebts.debts.length > 0),
});
