import { MenuItem } from "@mui/material";
import { AutocompleteTable } from "commons/components";
import { SelectInput } from "./SelectInput";
import { ITEM_COLUMN_TYPE } from "../../constants";

export const columns = ({
  products,
  getSearchOptionsProducts,
  taxesIva,
  taxesRete,
  handleChangeMultipleSelect,
}) => [
  {
    field: "product",
    headerName: "Producto",
    sortable: false,
    flex: 1,
    headerAlign: "center",
    cellClassName: "super-app-theme--cell cell-select",
    renderCell: (params) => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
          className="multipleSelect-1"
        >
          <AutocompleteTable
            name="product"
            options={products}
            tabIndex={params.tabIndex}
            getOptionLabel={(option) =>
              option?.fname
                ? `${option?.fcode.trim()} - ${option?.fname.trim()}`
                : ""
            }
            onChange={(event, option) => {
              event.preventDefault();
              handleChangeMultipleSelect(ITEM_COLUMN_TYPE.product, option);
            }}
            onInputChange={(event) => {
              getSearchOptionsProducts(
                ITEM_COLUMN_TYPE.product,
                event?.target?.value
              );
            }}
            value={params.value}
            tableConfig={{
              columns: [
                {
                  headerName: "Código",
                  getText: (option) => option?.fcode,
                },
                {
                  headerName: "Descripción",
                  getText: (option) => option?.fname.trim(),
                },
              ],
              rowClassName: "causation",
              getRowTooltipText: (option) =>
                `${option?.fcode} - ${option?.fname}`,
            }}
          />
        </div>
      );
    },
  },
  {
    field: "description",
    headerName: "Descripción",
    sortable: false,
    flex: 1,
    headerAlign: "center",
    editable: !false,
    renderCell: () => "",
    cellClassName: "cell-hidden",
  },
  {
    field: "quantity",
    headerName: "Cant.",
    type: "number",
    editable: !false,
    width: 80,
    sortable: false,
    headerAlign: "center",
    renderCell: () => "",
    cellClassName: "cell-hidden",
  },
  {
    field: "unitValue",
    headerName: "Valor unitario",
    type: "number",
    editable: false,
    sortable: false,
    width: 160,
    headerAlign: "center",
    renderCell: () => "",
    cellClassName: "cell-hidden",
  },
  {
    field: "discount",
    headerName: "Descuento",
    type: "number",
    editable: false,
    sortable: false,
    width: 150,
    headerAlign: "center",
    renderCell: () => "",
    cellClassName: "cell-hidden",
  },
  {
    field: "taxIva",
    headerName: "Imp. Cargo",
    sortable: false,
    width: 115,
    headerAlign: "center",
    cellClassName: "cell-select",
    renderCell: (params) => {
      return (
        <SelectInput
          className="multipleSelect-2"
          name="taxIva"
          onChange={(e) =>
            handleChangeMultipleSelect(ITEM_COLUMN_TYPE.taxIva, e.target.value)
          }
        >
          <MenuItem value={null} style={{ height: "36px" }}>
            {" "}
          </MenuItem>
          {taxesIva.map((tax) => (
            <MenuItem key={tax.id} value={tax.id}>
              {tax.description}
            </MenuItem>
          ))}
        </SelectInput>
      );
    },
  },
  {
    field: "taxRetefuente",
    headerName: "Imp. Retención",
    sortable: false,
    width: 115,
    headerAlign: "center",
    cellClassName: "cell-select",
    renderCell: (params) => (
      <SelectInput
        className="multipleSelect-3"
        name="taxRetefuente"
        onChange={(e) =>
          handleChangeMultipleSelect(
            ITEM_COLUMN_TYPE.taxRetefuente,
            e.target.value
          )
        }
      >
        <MenuItem value={null} style={{ height: "36px" }}>
          {}
        </MenuItem>
        {taxesRete.map((tax) => (
          <MenuItem key={tax.id} value={tax.id}>
            {tax.description}
          </MenuItem>
        ))}
      </SelectInput>
    ),
  },
  {
    field: "amountTotal",
    headerName: "Valor Total",
    sortable: false,
    width: 110,
    headerAlign: "center",
    type: "number",
    editable: false,
    renderCell: () => "",
    cellClassName: "cell-hidden",
  },
  {
    field: "deleteItem",
    headerName: "",
    sortable: false,
    width: 60,
    editable: false,
    renderCell: () => "",
    cellClassName: "cell-hidden",
  },
];
