import React, { useContext, useEffect, useRef, useState } from "react";
import * as amplitude from "@amplitude/analytics-browser";
import {
  Autocomplete,
  Divider,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { PrimaryButton, SecondaryButton } from "commons/buttons";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { AIStarsIcon, SearchIcon, SyncIcon } from "assets";
import { AccountingTabs } from "./AccountingTabs";
import { objectIsEmpty } from "utils/objectUtils";
import { styles } from "../styles";
import { ReconciliationsContext, UserContext } from "contexts";
import { formatRegisterDate } from "../utils";
import { useAssistedExpenses } from "hooks";
import {
  createAccountingReceipt,
  createCashReceipt,
  createPaymentRecord,
  findCoincidences,
  matchRecords,
} from "services";
import { formatDate } from "commons/modals/ExpandAssistedExpenses/utils";
import { TableSkeleton } from "commons";
import { useForm, useWatch } from "react-hook-form";
import { toast } from "react-toastify";
import { toastOptions } from "constants";
import { ReconciledData } from "./ReconciledData";
import { AccountingRegisterEmptyState } from "./AccountingRegisterEmptyState";
import { AccountingRadioGroup } from "./AccountingRadioGroup";
import { AccountingSupplierForm } from "./AccountingSupplierForm";
import { ReconcileForm } from "./ReconcileForm";
import { formatDateToSiigo, formatLocaleDate } from "utils";
import { format, parse } from "date-fns";
import { NumericFormat } from "react-number-format";
import stylesCSS from "../styles.module.css";

export const Accounting = ({
  accountingOption,
  setAccountingOption,
  selectedMovement,
  searchOptions,
  handleSearchChange,
  prevItems,
  costCenters,
  selectedCostCenter,
  setSelectedCostCenter,
  documentTypes,
  selectedDocumentType,
  setSelectedDocumentType,
  paymentMethods,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  beneficiaryIdSelected,
  accountingType,
  setAccountingType,
  getSearchOptions,
  loadingSupplierItems,
  getPaymentMethodOptions,
  comment,
  setComment,
  reconciledData,
  setIsMarkAsReconciledModalVisible,
  setReconciliationOption,
  setStatusSelected,
  resetAccounting,
  setSelectedMovement,
  showSiigoIntermittencies,
  setDocumentTypes,
  setLoadingSupplierItems,
  accountingAccountOptions,
  setAccountingAccountOptions,
  payanaReceipts,
  movementId,
  coincidences,
  setCoincidences,
  selectedProcessId,
  refreshBankMovementsTable,
  setIsPartiallyReconciledDetailModalVisible,
  setIsReconcileFiltersModalVisible,
  setUnreconcileModalVisible,
  setUnreconcileSet,
}) => {
  const { reconciliationsScreen, selectedBank } = useContext(
    ReconciliationsContext
  );
  const { currentUser } = useContext(UserContext);
  const { setItems } = useAssistedExpenses({
    isAssistedEgress: false,
    movementAmount:
      selectedMovement.amount < 0
        ? selectedMovement.amount * -1
        : selectedMovement.amount,
  });
  const textareaRef = useRef(null);
  const [advanceAmount, setAdvanceAmount] = useState(
    selectedMovement.amount < 0
      ? selectedMovement.amount * -1
      : selectedMovement.amount
  );
  const [costCenterMandatory, setCostCenterMandatory] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const [createRequestInProcess, setCreateRequestInProcess] = useState(false);
  const [selectedCoincidences, setSelectedCoincidences] = useState([]);

  const handleSelect = (id) => {
    if (selectedCoincidences.includes(id)) {
      setSelectedCoincidences(
        selectedCoincidences.filter((coincidenceId) => coincidenceId !== id)
      );
    } else {
      setSelectedCoincidences([...selectedCoincidences, id]);
    }
  };

  const isCoincidenceSelected = (id) => selectedCoincidences.includes(id);

  const { register, handleSubmit, formState, control, reset, setValue } =
    useForm({
      defaultValues: {
        documentType: selectedDocumentType,
        paymentMethod: selectedPaymentMethod,
        costCenter: selectedCostCenter,
        comments: comment,
        ...(accountingType === "accountingReceipt" && { amount: 0 }),
        ...(accountingType === "accountingReceipt" && {
          accountingAccount: "",
        }),
      },
      mode: "onBlur",
    });

  const formValues = useWatch({ control });

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // Resetea la altura
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Ajusta la altura
    }
  };

  let accountingReceiptType = "";
  let accountingReceiptAmount = 0;
  if (selectedMovement.status === "partially_reconciled") {
    const totalRelatedMovementsAmount =
      selectedMovement.related_movements.reduce(
        (acc, movement) => acc + movement.amount,
        0
      );

    const totalRelatedReceiptsAmount = selectedMovement.related_receipts.reduce(
      (acc, receipt) => acc + receipt.amount,
      0
    );

    accountingReceiptType =
      totalRelatedMovementsAmount - totalRelatedReceiptsAmount > 0
        ? "Credit"
        : "Debit";

    accountingReceiptAmount =
      totalRelatedMovementsAmount - totalRelatedReceiptsAmount < 0
        ? (totalRelatedMovementsAmount - totalRelatedReceiptsAmount) * -1
        : totalRelatedMovementsAmount - totalRelatedReceiptsAmount;
  } else {
    accountingReceiptType = selectedMovement.amount > 0 ? "Credit" : "Debit";
    accountingReceiptAmount =
      selectedMovement.amount < 0
        ? selectedMovement.amount * -1
        : selectedMovement.amount;
  }

  useEffect(() => {
    adjustTextareaHeight();
  }, [comment]);

  useEffect(() => {
    setItems(prevItems);
  }, [prevItems]);

  const handleRegister = {
    paymentRecord: () => {
      return createPaymentRecord({
        items:
          selectedRows.length > 0
            ? selectedRows.map((item) => ({
                ...item,
                Value: item.amountRegister,
                amountRegister: item.amountRegister,
                documentName: item.DuePrefix + "-" + item.DueConsecutive,
              }))
            : [],
        documentType: selectedDocumentType,
        type: selectedRows.length === 0 ? "Anticipo" : "Abono a deuda",
        costCenter: selectedCostCenter,
        paymentMethod: {
          ACPaymentMeanID: selectedPaymentMethod?.ACPaymentMeanID,
          Name: selectedPaymentMethod?.Name,
        },
        paidAt: formatDate(
          selectedMovement.issue_date || selectedMovement.createdAt
        ),
        comments: comment,
        provider: reconciliationsScreen.beneficiaryFilter.value,
        amountTotal:
          selectedMovement.amount < 0
            ? selectedMovement.amount * -1
            : selectedMovement.amount,
        reconciliationMovementId:
          payanaReceipts.length > 0 ? movementId : selectedMovement.id,
        ...(payanaReceipts.length > 0 && {
          receiptId: selectedMovement.id,
        }),
        reconciliationBankId: selectedBank.id,
        reconciliationProcessId: selectedProcessId,
      });
    },
    cashRegisterReceipt: () => {
      return createCashReceipt({
        items:
          selectedRows.length > 0
            ? selectedRows.map((item) => ({
                due: {
                  prefix: item.DuePrefix,
                  consecutive: item.DueConsecutive,
                  quote: item.DueQuote,
                  date: formatRegisterDate(item.DueDateDisplay),
                },
                value: item.amountRegister,
                amountRegister: item.amountRegister,
                documentName: item.DuePrefix + "-" + item.DueConsecutive,
              }))
            : [],
        documentType: selectedDocumentType,
        receiptType:
          selectedRows.length === 0 ? "AdvancePayment" : "DebtPayment",
        costCenter: selectedCostCenter,
        paymentMethod: {
          ACPaymentMeanID: selectedPaymentMethod?.ACPaymentMeanID,
          Name: selectedPaymentMethod?.Name,
        },
        issueDate: formatDateToSiigo(
          selectedMovement.issue_date || selectedMovement.createdAt
        ),
        comments: comment,
        provider: reconciliationsScreen.beneficiaryFilter.value,
        amountTotal: advanceAmount,
        reconciliationMovementId:
          payanaReceipts.length > 0 ? movementId : selectedMovement.id,
        ...(payanaReceipts.length > 0 && {
          receiptId: selectedMovement.id,
        }),
        reconciliationBankId: selectedBank.id,
        reconciliationProcessId: selectedProcessId,
      });
    },
    accountingReceipt: () => {
      return createAccountingReceipt({
        items: [
          {
            account: {
              code: selectedBank.erp_external_account_code.toString(),
              movement: accountingReceiptType === "Credit" ? "Debit" : "Credit",
            },

            customer: {
              identification:
                reconciliationsScreen?.beneficiaryFilter.value.Identification.toString(),
              branch_office: 0,
            },
            value: accountingReceiptAmount,
            cost_center: selectedCostCenter?.id,
          },
          {
            account: {
              code: formValues.accountingAccount.toString(),
              movement: accountingReceiptType === "Credit" ? "Credit" : "Debit",
            },
            customer: {
              identification:
                reconciliationsScreen?.beneficiaryFilter.value.Identification.toString(),
              branch_office: 0,
            },
            value: accountingReceiptAmount,
            cost_center: selectedCostCenter?.id,
          },
        ],
        documentType: {
          ...selectedDocumentType,
          id: selectedDocumentType.ERPDocumentTypeID,
        },
        issueDate: formatDateToSiigo(
          selectedMovement.issue_date || selectedMovement.createdAt
        ),
        comments: comment,
        provider: reconciliationsScreen.beneficiaryFilter.value,
        amountTotal: -1 * accountingReceiptAmount,
        reconciliationMovementId:
          payanaReceipts.length > 0 ? movementId : selectedMovement.id,
        ...(payanaReceipts.length > 0 && {
          receiptId: selectedMovement.id,
        }),
        reconciliationBankId: selectedBank.id,
        reconciliationProcessId: selectedProcessId,
      });
    },
  };

  const submitForm = handleSubmit(async () => {
    setCreateRequestInProcess(true);
    try {
      if (accountingOption === "register") {
        const res = await handleRegister[accountingType]();

        if (res?.response && res.response.status !== 200) {
          throw new Error("Error al registrar");
        }

        setCreateRequestInProcess(false);
        toast.success("¡Registro realizado con éxito!", toastOptions);
        resetAccounting();
        setSelectedMovement({});
        setReconciliationOption("not_reconciled");
        setStatusSelected("not_reconciled");
        amplitude.logEvent("END_CREATE_ERP_RECEIPT_FLOW", {
          user_id: currentUser.id,
        });
        refreshBankMovementsTable();
      } else {
        const selectedRowsIds = selectedRows.map((item) => item.id);
        const accountingReceiptIds = [
          ...selectedCoincidences,
          ...selectedRowsIds,
        ];
        const matchedRecords = accountingReceiptIds.map(
          (accountingReceiptId) => ({
            bankMovementId: selectedMovement.id,
            accountingReceiptId,
          })
        );
        const data = {
          reconciliationBankId: selectedBank.id,
          matchedRecords: matchedRecords,
        };

        const res = await matchRecords(data);

        if (res?.response && res.response.status !== 200) {
          throw new Error("Error al registrar");
        }

        setCreateRequestInProcess(false);
        toast.success("¡Conciliación realizada con éxito!", toastOptions);
        resetAccounting();
        setSelectedMovement({});
        setReconciliationOption("not_reconciled");
        setStatusSelected("not_reconciled");
        refreshBankMovementsTable();
      }
    } catch (error) {
      setCreateRequestInProcess(false);
      toast.error(
        "Ocurrió un error, por favor intente nuevamente.",
        toastOptions
      );
    }
  });

  const changeAdvanceAmount = (selectedMovement) => {
    let amount = selectedMovement.amount;
    if (selectedMovement.status === "partially_reconciled") {
      amount =
        selectedMovement.related_movements.reduce(
          (acc, movement) => acc + movement.amount,
          0
        ) -
        selectedMovement.related_receipts.reduce(
          (acc, receipt) => acc + receipt.amount,
          0
        );
    }
    setAdvanceAmount(amount < 0 ? amount * -1 : amount);
  };

  useEffect(() => {
    reset({
      documentType: selectedDocumentType,
      paymentMethod: selectedPaymentMethod,
      costCenter: selectedCostCenter,
      comments: comment,
      ...(accountingType === "accountingReceipt" && { amount: 0 }),
      ...(accountingType === "accountingReceipt" && {
        accountingAccount: "",
      }),
    });
    setAccountingType(
      selectedMovement.amount > 0 ? "cashRegisterReceipt" : "paymentRecord"
    );


    changeAdvanceAmount(selectedMovement);
  }, [selectedMovement]);

  const renderCoincidence = (coincidence) => {
    return (
      <div
        style={{
          width: "100%",
          border: isCoincidenceSelected(coincidence.id)
            ? "2px solid #7A5AF8"
            : "2px solid #EAECF0",
          borderRadius: "8px",
          padding: "16px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          height: "64px",
          backgroundColor: isCoincidenceSelected(coincidence.id)
            ? "#FAFAFF"
            : "#FFFFFF",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "16px",
            alignItems: "center",
          }}
        >
          <input
            type="checkbox"
            className={stylesCSS.input}
            checked={isCoincidenceSelected(coincidence.id)}
            onChange={() => handleSelect(coincidence.id)}
          />
          <AIStarsIcon color="#5925DC" size="30" />
          <div style={{ display: "flex", flexDirection: "column", gap: "2px" }}>
            <Typography
              sx={{ fontSize: "14px", fontWeight: "400", color: "#475467" }}
            >
              {formatLocaleDate(coincidence.issue_date, "dd/MM/yyyy")}
            </Typography>
            <Typography
              sx={{ fontSize: "14px", fontWeight: "400", color: "#475467" }}
            >
              {coincidence.type}
            </Typography>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "2px" }}>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              color: "#101828",
              alignSelf: "flex-end",
              textAlign: "right",
            }}
          >
            <NumericFormat
              value={parseInt(coincidence.registered_amount)}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
              decimalScale={2}
              prefix={"$"}
            />
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              alignSelf: "flex-end",
              textAlign: "right",
              color: "#475467",
            }}
          >
            {coincidence.accounting_receipt_number}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <div style={styles.accountingContainer}>
      <div style={styles.accountingColumn}>
        <div style={styles.accountingTitleContainer}>
          <Typography sx={styles.accountingTitle} noWrap>
            Contabilidad
          </Typography>
        </div>
        {selectedMovement.status === "reconciled" ||
        selectedMovement.status === "reconciled_outside" ||
        selectedMovement.accounting_receipt_id ? (
          <ReconciledData
            reconciledData={reconciledData}
            advanceAmount={advanceAmount}
            textareaRef={textareaRef}
            status={selectedMovement.status}
            reconciliationSet={selectedMovement.reconciliation_set_id}
            setUnreconcileModalVisible={setUnreconcileModalVisible}
            setUnreconcileSet={setUnreconcileSet}
          />
        ) : (
          <>
            {selectedMovement.status === "partially_reconciled" && (
              <div
                style={{
                  border: "1.5px solid #D9D6FE",
                  borderRadius: "8px",
                  padding: "16px",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "#101828",
                    }}
                  >
                    Este movimiento bancario tiene una conciliación parcial
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#344054",
                    }}
                  >
                    Saldo pendiente de conciliación
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    justifyContent: "flex-end",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#5925DC",
                      alignSelf: "flex-end",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      setIsPartiallyReconciledDetailModalVisible(true)
                    }
                  >
                    Ver detalle
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "#B42318",
                      alignSelf: "flex-end",
                    }}
                  >
                    <NumericFormat
                      value={
                        selectedMovement.related_movements.reduce(
                          (acc, movement) => acc + movement.amount,
                          0
                        ) -
                        selectedMovement.related_receipts.reduce(
                          (acc, receipt) => acc + receipt.amount,
                          0
                        )
                      }
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      decimalScale={2}
                      prefix={"$"}
                    />
                  </Typography>
                </div>
              </div>
            )}
            <AccountingTabs
              tabSelected={accountingOption}
              setTabSelected={setAccountingOption}
            />
            {accountingOption === "register" ? (
              objectIsEmpty(selectedMovement) ? (
                <AccountingRegisterEmptyState />
              ) : (
                <>
                  <AccountingRadioGroup
                    accountingType={accountingType}
                    setAccountingType={setAccountingType}
                    selectedMovement={selectedMovement}
                    setDocumentTypes={setDocumentTypes}
                    setLoadingSupplierItems={setLoadingSupplierItems}
                    setAccountingAccountOptions={setAccountingAccountOptions}
                  />
                  <div style={styles.accountingItemsContainer}>
                    <Typography sx={styles.supplierTitle}>
                      Nombre del tercero
                    </Typography>
                    <Autocomplete
                      disabled={createRequestInProcess}
                      options={searchOptions}
                      groupBy={(option) => option.type}
                      size="small"
                      sx={{ width: "100%" }}
                      freeSolo
                      getOptionLabel={(option) => {
                        return option?.FullName || "";
                      }}
                      onInputChange={(event) => {
                        if (event?.target?.value?.length >= 3) {
                          getSearchOptions(event.target.value);
                        }
                      }}
                      onChange={(event, option) => {
                        if (option?.AccountID) {
                          handleSearchChange(option);
                        } else {
                          handleSearchChange();
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            style: { fontSize: 14 },
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                          placeholder={"Buscar tercero"}
                        />
                      )}
                      value={
                        reconciliationsScreen?.beneficiaryFilter.value || {
                          FullName: "",
                        }
                      }
                    />
                    {showSiigoIntermittencies && (
                      <Typography
                        sx={{ color: "rgba(240, 68, 56, 1)", fontSize: 12 }}
                      >
                        Siigo se encuentra con intermitencias, por favor intenta
                        nuevamente en un minuto.
                      </Typography>
                    )}
                    {loadingSupplierItems ? (
                      <div
                        className="table-skeleton-page-initial-loading"
                        style={{ width: "100%", marginBottom: "16px" }}
                      >
                        <TableSkeleton />
                      </div>
                    ) : (
                      !objectIsEmpty(
                        reconciliationsScreen.beneficiaryFilter.value
                      ) && (
                        <AccountingSupplierForm
                          submitForm={submitForm}
                          createRequestInProcess={createRequestInProcess}
                          register={register}
                          selectedDocumentType={selectedDocumentType}
                          setSelectedDocumentType={setSelectedDocumentType}
                          setCostCenterMandatory={setCostCenterMandatory}
                          documentTypes={documentTypes}
                          formState={formState}
                          paymentMethods={paymentMethods}
                          setSelectedPaymentMethod={setSelectedPaymentMethod}
                          setValue={setValue}
                          getPaymentMethodOptions={getPaymentMethodOptions}
                          selectedPaymentMethod={selectedPaymentMethod}
                          costCenters={costCenters}
                          setSelectedCostCenter={setSelectedCostCenter}
                          costCenterMandatory={costCenterMandatory}
                          formValues={formValues}
                          prevItems={prevItems}
                          textareaRef={textareaRef}
                          comment={comment}
                          setComment={setComment}
                          advanceAmount={advanceAmount}
                          setIsMarkAsReconciledModalVisible={
                            setIsMarkAsReconciledModalVisible
                          }
                          setAdvanceAmount={setAdvanceAmount}
                          selectedMovement={selectedMovement}
                          changeAdvanceAmount={changeAdvanceAmount}
                          setSelectedRows={setSelectedRows}
                          accountingType={accountingType}
                          accountingAccountOptions={accountingAccountOptions}
                        />
                      )
                    )}
                  </div>
                </>
              )
            ) : objectIsEmpty(selectedMovement) ? (
              <AccountingRegisterEmptyState />
            ) : (
              <>
                <PrimaryButton
                  action={() => {
                    const date = parse(
                      selectedMovement.issue_date,
                      "dd/MM/yyyy",
                      new Date()
                    );
                    const formattedDate = format(date, "yyyy-MM-dd");

                    findCoincidences({
                      reconciliationBankId: selectedBank.id,
                      amount: selectedMovement.amount,
                      issueDate: formattedDate,
                      description: selectedMovement.description,
                    }).then((res) => {
                      setCoincidences(res);
                      if (res.length === 0) {
                        toast.warn(
                          "No encontramos coincidencias para este movimiento, puedes buscar manualmente.",
                          toastOptions
                        );
                      }
                    });
                  }}
                  text={"Buscar coincidencias"}
                  width={"240px"}
                  style={{
                    marginTop: "8px",
                    marginBottom: "8px",
                    justifyContent: "space-between",
                  }}
                  icon={<AIStarsIcon />}
                  endIcon={<SyncIcon color="#FFFFFF" />}
                  isDisable={coincidences.length > 0}
                />
                {coincidences.length > 0 && (
                  <>
                    <Typography
                      sx={{
                        color: "#101828",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      Encontramos {coincidences.length}{" "}
                      {coincidences.length === 1
                        ? "coincidencia"
                        : "coincidencias"}
                    </Typography>
                    {coincidences.map((coincidence) =>
                      renderCoincidence(coincidence)
                    )}
                  </>
                )}
                <Divider sx={{ backgroundColor: "#EAECF0", height: "2px" }} />
                <div style={styles.accountingItemsContainer}>
                  <Typography sx={styles.supplierTitle}>
                    Buscar por tercero
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "8px",
                      width: "100%",
                    }}
                  >
                    <Autocomplete
                      disabled={createRequestInProcess}
                      options={searchOptions}
                      groupBy={(option) => option.type}
                      size="small"
                      sx={{ width: "100%" }}
                      freeSolo
                      getOptionLabel={(option) => {
                        return option?.FullName || "";
                      }}
                      onInputChange={(event) => {
                        if (event?.target?.value?.length >= 3) {
                          getSearchOptions(event.target.value);
                        }
                      }}
                      onChange={(event, option) => {
                        if (option?.AccountID) {
                          handleSearchChange(option);
                        } else {
                          handleSearchChange();
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            style: { fontSize: 14 },
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                          placeholder={"Buscar"}
                        />
                      )}
                      value={
                        reconciliationsScreen?.beneficiaryFilter.value || {
                          FullName: "",
                        }
                      }
                    />
                    <SecondaryButton
                      action={() => {
                        setIsReconcileFiltersModalVisible(true);
                      }}
                      text={"Filtros avanzados"}
                      width={"147px"}
                    />
                  </div>

                  {showSiigoIntermittencies && (
                    <Typography
                      sx={{ color: "rgba(240, 68, 56, 1)", fontSize: 12 }}
                    >
                      Siigo se encuentra con intermitencias, por favor intenta
                      nuevamente en un minuto.
                    </Typography>
                  )}
                  {loadingSupplierItems ? (
                    <div
                      className="table-skeleton-page-initial-loading"
                      style={{ width: "100%", marginBottom: "16px" }}
                    >
                      <TableSkeleton />
                    </div>
                  ) : (
                    (!objectIsEmpty(
                      reconciliationsScreen.beneficiaryFilter.value
                    ) ||
                      selectedCoincidences.length > 0) && (
                      <ReconcileForm
                        submitForm={submitForm}
                        createRequestInProcess={createRequestInProcess}
                        register={register}
                        selectedDocumentType={selectedDocumentType}
                        setSelectedDocumentType={setSelectedDocumentType}
                        setCostCenterMandatory={setCostCenterMandatory}
                        documentTypes={documentTypes}
                        formState={formState}
                        paymentMethods={paymentMethods}
                        setSelectedPaymentMethod={setSelectedPaymentMethod}
                        setValue={setValue}
                        getPaymentMethodOptions={getPaymentMethodOptions}
                        selectedPaymentMethod={selectedPaymentMethod}
                        costCenters={costCenters}
                        setSelectedCostCenter={setSelectedCostCenter}
                        costCenterMandatory={costCenterMandatory}
                        formValues={formValues}
                        prevItems={prevItems}
                        textareaRef={textareaRef}
                        comment={comment}
                        setComment={setComment}
                        advanceAmount={advanceAmount}
                        setIsMarkAsReconciledModalVisible={
                          setIsMarkAsReconciledModalVisible
                        }
                        setAdvanceAmount={setAdvanceAmount}
                        selectedMovement={selectedMovement}
                        changeAdvanceAmount={changeAdvanceAmount}
                        setSelectedRows={setSelectedRows}
                        accountingType={accountingType}
                        accountingAccountOptions={accountingAccountOptions}
                        selectedCoincidences={selectedCoincidences}
                      />
                    )
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};
