import { MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { InputForm, FormBilling } from "commons";
import { PrimaryButton, SecondaryButton } from "commons/buttons";
import {
  createBillingCompany,
  getSiigoCities,
  getUsersCompany,
  updateBOBilling,
} from "services";
import { toast } from "react-toastify";
import { toastOptions } from "constants";
import { TrackJS } from "trackjs";

export const ModalBilling = ({
  show = false,
  onHide = () => {},
  billing = null,
  companySelected = null,
  showUser = true,
  refreshSetCompanyBillings,
  isShowDetail,
}) => {
  const isCreateBilling = !billing?.id;

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    defaultValues: billing,
  });
  const formValues = useWatch({ control });
  const [isLoading, setIsLoading] = useState(false);
  const [isEditable, setIsEditable] = useState(isCreateBilling || isShowDetail);
  const [users, setUsers] = useState([]);

  const onSubmited = handleSubmit(async (data) => {
    try {
      setIsLoading(true);
      const formatBilling = {
        documentNumber: data.document_number,
        documentType: data.document_type,
        phone: data.phone,
        address: data?.address || "",
        email: data.email,
        contactName: data.contact_name,
        contactSurname: data.contact_surname,
        municipality: data.city?.state_code,
        organizationType: data.organization_type,
        city: data.city?.city_code,
        companyId: companySelected.id,
        accountantUserId: data.accountant_user_id,
        businessName: data.business_name,
      };

      let textToast = "Información de facturación actualizada";
      let respBilling;
      if (billing?.id) {
        respBilling = await updateBOBilling(formatBilling, billing.id);
        if (!respBilling) {
          return toast.error(
            "No se pudo actualizar la información de facturación",
            toastOptions
          );
        }
        textToast = "Información de facturación actualizada";
      } else {
        respBilling = await createBillingCompany(formatBilling);
        if (!respBilling) {
          return toast.error(
            "No se pudo actualizar la información de facturación",
            toastOptions
          );
        }
        textToast = "Información de facturación creada";
      }

      refreshSetCompanyBillings();
      toast.success(textToast, toastOptions);
      onHide();
    } catch (error) {
      TrackJS.console.error(error);
      toast.error(
        "No se pudo actualizar la información de facturación",
        toastOptions
      );
    } finally {
      setIsLoading(false);
      setIsEditable(false);
    }
  });

  useEffect(() => {
    if (billing?.city) {
      getSiigoCities(billing.city).then((reps) => {
        setValue("city", reps);
        setValue("department", reps?.state_name);
      });
    }
  }, [billing]);

  useEffect(() => {
    getUsersCompany(companySelected.id).then((res) => {
      setUsers(res);
    });
  }, []);

  const inputAccountUser = () => {
    if ((showUser && isCreateBilling) || billing?.accountant_user_id)
      return (
        <InputForm
          label="Usuario Contable"
          name="accountant_user_id"
          disabled={true}
          errors={errors}
          customInput={
            <Select
              name="accountant_user_id"
              size="small"
              variant="outlined"
              displayEmpty={true}
              style={{ fontSize: "14px" }}
              value={formValues.accountant_user_id}
              disabled={!isEditable}
              renderValue={(option) => {
                return (
                  users.find((user) => user.userId === option)?.name || option
                );
              }}
              {...register("accountant_user_id", {
                onChange: (e) => {
                  setValue("accountant_user_id", e.target.value);
                },
              })}
            >
              {users.map((user) => (
                <MenuItem
                  key={user.userId}
                  value={user.userId}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    borderBottom: "1px solid #EAECF0",
                  }}
                >
                  <snap
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      lineHeight: "20px",
                    }}
                  >
                    {user.name}
                  </snap>
                  <snap
                    style={{
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "18px",
                      color: "#667085",
                    }}
                  >
                    {user.email}
                  </snap>
                </MenuItem>
              ))}
            </Select>
          }
        />
      );
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Body
        style={{ width: "100%", maxWidth: "400px", minWidth: "568px" }}
      >
        <p
          style={{
            padding: "16px 0px",
            marginBottom: "0px",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 500,
          }}
        >
          Datos fiscales
        </p>
        <FormBilling
          errors={errors}
          formValues={formValues}
          setValue={setValue}
          register={register}
          isEditable={isEditable}
          isCreateBilling={isCreateBilling}
          inputAccountUser={inputAccountUser()}
        />
        {(isShowDetail || isCreateBilling) && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              gap: "20px",
              marginTop: "24px",
            }}
          >
            <SecondaryButton
              isDisable={isLoading}
              text="Cancelar"
              action={onHide}
              width="50%"
            />
            <PrimaryButton
              loading={isLoading}
              text={isEditable ? "Guardar" : "Editar"}
              action={
                isEditable ? () => onSubmited() : () => setIsEditable(true)
              }
              width="50%"
            />
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
