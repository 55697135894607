import React, { useContext, useRef, useState } from "react";
import * as amplitude from "@amplitude/analytics-browser";
import {
  Divider,
  FormControlLabel,
  FormGroup,
  Menu,
  Typography,
} from "@mui/material";
import { PrimaryButton, SecondaryButton } from "commons/buttons";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { UserContext } from "contexts";
import { TypeButton } from "./TypeButton";
import { styles } from "../styles";
import stylesCSS from "../styles.module.css";

export const TypeDropdown = ({
  type,
  setType,
  selectedType,
  setSelectedType,
  setPageIndex,
}) => {
  const { currentUser } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState(type);
  const searchRef = useRef(null);
  const open = !!anchorEl;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    searchRef?.current?.focus();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const resetType = () => {
    setPageIndex(1);
    handleClose();
    setSelectedType(false);
    setSelectedOptions([
      { id: "invoice", name: "Proveedores", enabled: false },
      { id: "payroll", name: "Nómina", enabled: false },
      { id: "collection", name: "Cobros", enabled: false },
    ]);
    setType([
      { id: "invoice", name: "Proveedores", enabled: false },
      { id: "payroll", name: "Nómina", enabled: false },
      { id: "collection", name: "Cobros", enabled: false },
    ]);
  };

  const updateType = (typeId, enabled) => {
    setSelectedOptions(
      selectedOptions.map((option) =>
        option.id === typeId ? { ...option, enabled: enabled } : option
      )
    );
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <TypeButton
          open={open}
          action={handleClick}
          text={
            !selectedType
              ? "Tipo de operación"
              : `${type
                  .filter((currentType) => currentType.enabled)
                  .map((currentType) => currentType.name)
                  .join(" - ")}`
          }
        />
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          slotProps={{
            paper: {
              style: {
                height: 306,
                minHeight: 90,
                maxHeight: 306,
                width: 390,
                padding: 16,
              },
            },
          }}
        >
          <Typography sx={styles.typeFilterTitle}>
            Filtra por tipo de operación
          </Typography>
          <Typography sx={styles.typeFilterSubtitle}>
            Puedes hacer una selección múltiple
          </Typography>
          <Divider style={styles.divider} />
          <FormGroup>
            <FormControlLabel
              control={
                <input
                  id="typeInvoices"
                  type="checkbox"
                  className={stylesCSS.input}
                  defaultChecked={
                    selectedType &&
                    selectedOptions.find(
                      (currentType) => currentType.id === "invoice"
                    ).enabled
                  }
                  onChange={(e) => updateType("invoice", e.target.checked)}
                />
              }
              label={
                <label htmlFor="typeInvoices" style={styles.checkboxInputLabel}>
                  Pagos a proveedores
                </label>
              }
              labelPlacement="end"
              sx={{ marginLeft: "2px", marginBottom: "12px" }}
            />
            <FormControlLabel
              control={
                <input
                  id="typePayroll"
                  type="checkbox"
                  className={stylesCSS.input}
                  defaultChecked={
                    selectedType &&
                    selectedOptions.find(
                      (currentType) => currentType.id === "payroll"
                    ).enabled
                  }
                  onChange={(e) => updateType("payroll", e.target.checked)}
                />
              }
              label={
                <label htmlFor="typePayroll" style={styles.checkboxInputLabel}>
                  Pagos a nómina
                </label>
              }
              labelPlacement="end"
              sx={{ marginLeft: "2px", marginBottom: "12px" }}
            />
            <FormControlLabel
              control={
                <input
                  id="typeCollection"
                  type="checkbox"
                  className={stylesCSS.input}
                  defaultChecked={
                    selectedType &&
                    selectedOptions.find(
                      (currentType) => currentType.id === "collection"
                    ).enabled
                  }
                  onChange={(e) => updateType("collection", e.target.checked)}
                />
              }
              label={
                <label
                  htmlFor="typeCollection"
                  style={styles.checkboxInputLabel}
                >
                  Cobros
                </label>
              }
              labelPlacement="end"
              sx={{ marginLeft: "2px", marginBottom: "12px" }}
            />
          </FormGroup>
          <Divider style={styles.divider} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              width: "100%",
              gap: "12px",
            }}
          >
            <SecondaryButton
              text="Limpiar"
              action={() => {
                resetType();
              }}
              width="88px"
            />
            <PrimaryButton
              text="Aplicar"
              action={() => {
                if (
                  selectedOptions.filter((currentType) => currentType.enabled)
                    .length > 0
                ) {
                  amplitude.logEvent("USE_FILTER", {
                    user_id: currentUser.id,
                    filter_type: "transaction_type",
                    filter_value: `${selectedOptions
                      .filter((currentType) => currentType.enabled)
                      .map((currentType) => currentType.name)
                      .join(" - ")}`,
                  });
                  setSelectedType(
                    selectedOptions
                      .filter((currentType) => currentType.enabled)
                      .map((currentType) => currentType.id)
                      .join(",")
                  );
                } else {
                  setSelectedType(false);
                }
                setType(selectedOptions);
                setPageIndex(1);
                handleClose();
              }}
              width="88px"
            />
          </div>
        </Menu>
      </div>
    </>
  );
};
