import {
  Autocomplete,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@mui/material";
import { SearchIcon } from "assets";
import { useEffect, useState } from "react";

const isAlphanumericKey = (event) => {
  const key = event.key;
  const alphanumericRegex = /^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ0-9]$/;
  return alphanumericRegex.test(key);
};

export const ServiceCompanyDropdown = ({
  onInputChange,
  loading,
  value,
  onChange,
  options,
}) => {
  const [typing, isTyping] = useState(false);
  useEffect(() => {
    if (loading) {
      isTyping(false);
    }
  }, [loading]);
  const loadingText = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <span>Buscando...</span> <CircularProgress size={15} />
    </div>
  );
  return (
    <div>
      <label className="debts-search-form-label" htmlFor="searchOptionSelected">
        Ingresa el nombre del servicio
      </label>
      <Autocomplete
        id="searchOptionSelected"
        onKeyDown={(e) => isAlphanumericKey(e) && isTyping(true)}
        options={options}
        loading={loading}
        loadingText={loadingText}
        noOptionsText={typing ? "Escribiendo..." : "Sin resultados"}
        size="small"
        sx={{
          width: "350px",
          minWidth: "350px",
        }}
        getOptionLabel={(option) => option?.name || ""}
        onChange={(event, option) => onChange(option)}
        onInputChange={(e) => {
          if (!e || e?.target?.value === 0) {
            return;
          }
          if (!e.target.value) {
            return onInputChange("");
          }
          onInputChange(
            typeof e.target.value !== "string" ? "" : e.target.value
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              style: { fontSize: 14 },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            placeholder="Buscar"
          />
        )}
        value={value}
      />
    </div>
  );
};
