import React from "react";
import { styles } from "./styles";
import { ListCheckIcon } from "assets";

export const TransactionCost = () => {
  return (
    <>
      <p
        style={{
          ...styles.cardInformationTitle,
          marginTop: "16px",
          marginBottom: "8px",
        }}
      >
        Costo por transacción
      </p>
      <div>
        <div style={styles.cardInformationListContainer}>
          <ListCheckIcon stroke="#6938EF" strokeWidth="2.5" />
          <p style={styles.transactionCostTextList}>
            {"Pagos -> $3.300/proveedor"}
          </p>
        </div>
        <div style={styles.cardInformationListContainer}>
          <ListCheckIcon stroke="#6938EF" strokeWidth="2.5" />
          <p style={styles.transactionCostTextList}>
            {"Cobros -> $5.000/proveedor"}
          </p>
        </div>
      </div>
    </>
  );
};
