import { useContext } from "react";
import { Footer } from "../../../commons";
import { PaymentsContext, UserContext } from "../../../contexts";

export const FooterHandler = ({
  selectedRows,
  totalAmount,
  openMarkAsPaidModal,
  payLater,
  openWompiModal,
  totalSelected,
  disabled,
  footerDropdown,
  deleteAction = () => {},
  approveAction = () => {},
}) => {
  const { approvationNeeded, currentUserIsAdmin } = useContext(UserContext);

  const { subscription } = useContext(PaymentsContext);

  return (
    <Footer
      disabled={disabled || selectedRows.length === 0}
      disabledApproval={approvationNeeded && !currentUserIsAdmin}
      totalAmount={totalAmount}
      deleteAction={deleteAction}
      disabledMarkAsPaid={selectedRows.length < 1}
      markAsPaidAction={openMarkAsPaidModal}
      showPayLater
      disablePayLater={selectedRows.length < 1 || totalAmount <= 0}
      payLaterAction={payLater}
      openWompiModal={openWompiModal}
      disableWompi={selectedRows.length < 1 || totalAmount <= 0}
      showApprove={approvationNeeded && currentUserIsAdmin}
      approveAction={approveAction}
      totalSelected={totalSelected}
      disabledTooltip={disabled}
      footerDropdown={footerDropdown}
      subscription={subscription}
    />
  );
};
