import React from "react";
import { styles } from "../styles";
import { Divider, Typography } from "@mui/material";
import { reconciledType } from "../utils";
import { NumericFormat } from "react-number-format";
import { PrimaryButton } from "commons/buttons";
import { Spinner } from "react-bootstrap";
import { set } from "date-fns";

export const ReconciledData = ({
  reconciledData,
  advanceAmount,
  textareaRef,
  status,
  reconciliationSet,
  setUnreconcileModalVisible,
  setUnreconcileSet,
}) => {
  return (
    <>
      {reconciledData.map((reconciledDataItem, index) => (
        <div style={styles.reconciledContainer} key={index}>
          {status === "reconciled_outside" ? (
            <Typography sx={styles.reconciledHeader}>
              Se marcó como conciliado
            </Typography>
          ) : (
            <Typography sx={styles.reconciledHeader}>
              Se creó un{" "}
              <strong>{reconciledType[reconciledDataItem.type]}</strong>
            </Typography>
          )}
          {status === "reconciled_outside" ? (
            <Typography sx={styles.reconciledText}>
              Comprobante:{" "}
              <strong>
                {reconciledDataItem.accounting_receipt_number?.toUpperCase()}
              </strong>
            </Typography>
          ) : (
            <>
              <Typography sx={styles.reconciledText}>
                Comprobante:{" "}
                <strong>
                  {reconciledDataItem.accounting_receipt_number?.toUpperCase()}
                </strong>
              </Typography>
              <Typography sx={styles.reconciledText}>
                Nombre del tercero:{" "}
                <strong>
                  {reconciledDataItem.erp_third_name?.toUpperCase()}
                </strong>
              </Typography>
              <Typography sx={styles.reconciledText}>
                Tipo de documento:{" "}
                <strong>{reconciledDataItem.erp_document_type}</strong>
              </Typography>
              {reconciledDataItem.type !== "CC" && (
                <Typography sx={styles.reconciledText}>
                  Forma de pago:{" "}
                  <strong>{reconciledDataItem.erp_payment_method}</strong>
                </Typography>
              )}
              {reconciledDataItem.erp_cost_center && (
                <Typography sx={styles.reconciledText}>
                  Centro de costos:{" "}
                  <strong>{reconciledDataItem.erp_cost_center}</strong>
                </Typography>
              )}
              {reconciledDataItem.type !== "CC" &&
                (reconciledDataItem.erpDocuments?.length === 0 ||
                  (reconciledDataItem.erpDocuments?.length > 0 &&
                    reconciledDataItem.erpDocuments.reduce(
                      (acc, row) => acc + row.amount,
                      0
                    ) < advanceAmount)) && (
                  <>
                    <Typography
                      sx={{
                        ...styles.reconciledText,
                        marginTop: "8px",
                      }}
                    >
                      Anticipo por{" "}
                      <NumericFormat
                        value={
                          advanceAmount -
                          reconciledDataItem.erpDocuments?.reduce(
                            (acc, row) => acc + row.amount,
                            0
                          )
                        }
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        prefix={"$"}
                        decimalScale={2}
                      />
                    </Typography>
                  </>
                )}
              {reconciledDataItem.erpDocuments?.length > 0 && (
                <>
                  <Typography
                    sx={{
                      ...styles.reconciledText,
                      marginTop: "8px",
                    }}
                  >
                    Abonos a deuda
                  </Typography>
                  <div style={styles.reconciledTableContainer}>
                    <div
                      style={{
                        ...styles.reconciledTableRow,
                        marginBottom: "8px",
                      }}
                    >
                      <Typography sx={styles.reconciledTableHeader}>
                        Documento
                      </Typography>
                      <Typography sx={styles.reconciledTableHeader}>
                        Monto registrado
                      </Typography>
                    </div>
                    <Divider sx={styles.reconciledDivider} />
                    {reconciledDataItem.erpDocuments?.map((document, index) => {
                      return (
                        <>
                          <div style={styles.reconciledTableRow}>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "500",
                                color: "#475467",
                              }}
                            >
                              {document.description}
                            </Typography>
                            <Typography sx={styles.reconciledTableText}>
                              <NumericFormat
                                value={document.amount}
                                displayType={"text"}
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix={"$"}
                                decimalScale={2}
                              />
                            </Typography>
                          </div>
                          {index !==
                            reconciledDataItem.erpDocuments.length - 1 && (
                            <Divider
                              sx={{
                                ...styles.reconciledDivider,
                                marginBottom: "6px",
                              }}
                            />
                          )}
                        </>
                      );
                    })}
                  </div>
                </>
              )}
            </>
          )}
          {reconciledDataItem.comments && (
            <>
              <Typography
                sx={{
                  ...styles.reconciledText,
                  marginTop: "8px",
                }}
              >
                Comentarios
              </Typography>
              <textarea
                ref={textareaRef}
                style={styles.reconciledTextarea}
                name="comments"
                value={reconciledDataItem.comments}
                disabled
              />
            </>
          )}
        </div>
      ))}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "16px",
          // justifyContent:
          //   selectedRows.length > 0 && advanceAmount
          //     ? "space-between"
          //     : "flex-end",
          alignItems: "center",
          marginTop: "8px",
          position: "sticky",
          bottom: "-4px",
          backgroundColor: "#FFFFFF",
          marginLeft: "-16px",
          marginRight: "-16px",
          padding: "8px 16px 8px 16px",
        }}
      >
        <div style={styles.formButtonsContainer}>
          <PrimaryButton
            text={
              false ? <Spinner animation="border" size="sm" /> : "Desconciliar"
            }
            action={() => {
              setUnreconcileSet(reconciliationSet);
              setUnreconcileModalVisible(true);
            }}
            width="182px"
            isDisable={false}
          />
        </div>
      </div>
    </>
  );
};
