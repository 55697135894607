import React from "react";

export const BlockIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="34"
      viewBox="0 0 35 34"
      fill="none"
    >
      <g clipPath="url(#clip0_376_23698)">
        <path
          d="M17.5 0C8.11603 0 0.5 7.61603 0.5 17C0.5 26.3839 8.11603 33.9999 17.5 33.9999C26.8839 33.9999 34.4999 26.3839 34.4999 17C34.4999 7.61603 26.884 0 17.5 0ZM5.3053 17C5.3053 10.2453 10.7906 4.76 17.5 4.76C20.0386 4.76 22.4413 5.53069 24.3906 6.89064L7.43602 23.8907C6.076 21.9413 5.3053 19.5386 5.3053 17ZM17.5 29.1946C14.9613 29.1946 12.5586 28.4239 10.6093 27.064L27.564 10.1093C28.924 12.0587 29.6946 14.4613 29.6946 17C29.6946 23.7546 24.2546 29.1946 17.5 29.1946Z"
          fill="#F04438"
        />
      </g>
      <defs>
        <clipPath id="clip0_376_23698">
          <rect
            width="34"
            height="34"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
