import { requestContainer } from "../requestContainer";

export const getBOCompany = async (companyId) => {
  return requestContainer({
    method: "GET",
    url: `/backoffice/companies/${companyId}`,
  });
};

export const getBOCompanyBillings = (query) => {
  return requestContainer({
    method: "GET",
    url: `/billings?${query}`,
  });
};

export const getSearchSiigoCities = (query = null) => {
  let url = `/billing/cities`;
  if (query) {
    url += `?name=${query}`;
  }
  return requestContainer({
    method: "GET",
    url: url,
  });
};

export const getSiigoCities = (query = null) => {
  return requestContainer({
    method: "GET",
    url: `/billing/city?q=${query}`,
  });
};

export const createBillingCompany = (body) => {
  return requestContainer({
    method: "POST",
    url: `/billings`,
    data: body,
  });
};

export const getUsersCompany = (companyId) => {
  return requestContainer({
    method: "GET",
    url: `/user-company/${companyId}`,
  });
};
