import { format } from "date-fns";

export const boServicesReceiptsColumns = [
  {
    field: "service_name",
    Header: "Service",
    headerName: "Servicio",
    accessor: "service_name",
    id: "service_name",
  },
  {
    field: "service_number",
    Header: "Información de pago",
    headerName: "Información",
    accessor: "service_number",
    id: "service_number",
  },

  {
    field: "company_name",
    Header: "Empresa",
    headerName: "Empresa",
    accessor: "company_name",
    id: "company_name",
  },
  {
    field: "created_at",
    Header: "Fecha de pago",
    headerName: "Fecha de pago",
    accessor: "created_at",
    id: "created_at",
    Cell: ({ value }) => {
      if (value) {
        return format(new Date(value.slice(0, -1)), "dd/MM/yyyy  HH:mm 'hs'");
      }
    },
  },
  {
    field: "amount",
    Header: "Monto",
    headerName: "Monto",
    accessor: "amount",
    id: "amount",
  },
  {
    field: "payment_gateway",
    Header: "Gateway",
    headerName: "Gateway",
    accessor: "payment_gateway",
    id: "payment_gateway",
    Cell: ({ value }) => {
      if (value) {
        return value.toUpperCase();
      }
    },
  },
  {
    field: "transaction_hash",
    Header: "Código",
    headerName: "Código",
    accessor: "transaction_hash",
    id: "transaction_hash",
    Cell: ({ value }) => {
      if (value) {
        return value.slice(0, 4).toUpperCase();
      }
    },
  },
  {
    field: "status",
    Header: "Estado",
    headerName: "Estado",
    accessor: "status",
    id: "status",
  },
  {
    field: "file",
    Header: "Doc. soporte",
    headerName: "Doc. soporte",
    accessor: "file",
    id: "file",
  },
];
