import { FooterHandler } from "screens/ListPage/components";

export const PageFooter = ({
  selectedRows,
  openWompiModal,
  selectedRowsIds,
  totalAmount,
  payLater,
}) => {
  return (
    <FooterHandler
      selectedRows={selectedRows}
      selectedRowsIds={selectedRowsIds}
      totalAmount={totalAmount}
      deleteSelectedInvoices={() => {}}
      openMarkAsPaidModal={() => {}}
      payLater={payLater}
      openWompiModal={openWompiModal}
      totalSelected={selectedRows.length}
      pageIndex={1}
    />
  );
};
