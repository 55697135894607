import React from "react";
import Dropzone from "react-dropzone";
import {
  ClipIcon,
  CloseIcon,
  DownloadFileIcon2,
  DownloadFileIcon3,
  DownloadFileIcon4,
  ModalDownloadIcon,
  UploadCloudIcon,
} from "../../../../assets";
import { fileUploadStyles } from "./styles";
import { IconButton } from "@mui/material";

const BankExtractFileUpload = ({
  selectedFiles,
  setSelectedFiles,
  renderImg = false,
  isInvoiceFile = false,
}) => {
  const onDrop = (files) => {
    if (files.length > 0) {
      setSelectedFiles(files);
    }
  };
  const { styles } = fileUploadStyles;
  return (
    <Dropzone onDrop={onDrop}>
      {({ getRootProps, getInputProps }) => (
        <section style={{ width: "100%" }}>
          <div
            {...getRootProps({
              className: "bank-extract-dropzone",
            })}
          >
            <input {...getInputProps()} />
            {selectedFiles ? (
              selectedFiles.map((file) => (
                <div
                  className="selected-file"
                  style={{
                    ...styles.imageContainer,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {renderImg && (
                    <img
                      style={styles.image}
                      src={URL.createObjectURL(file)}
                      alt={file.name}
                    />
                  )}
                  <span
                    style={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {file.name}
                  </span>
                  <div>
                    <IconButton
                      onClick={() => setSelectedFiles(null)}
                      color="primary"
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </div>
              ))
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <UploadCloudIcon />
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginLeft: "16px",
                  }}
                >
                  <p
                    style={{
                      color: "#1D2939",
                      fontSize: "14px",
                      fontWeight: "600",
                      textAlign: "start",
                      alignSelf: "flex-start",
                      marginBottom: "6px",
                    }}
                  >
                    Importar el archivo
                  </p>
                  <p
                    style={{
                      color: "#667085",
                      fontSize: "14px",
                      fontWeight: "500",
                      textAlign: "start",
                      marginBottom: "0px",
                      alignSelf: "flex-start",
                    }}
                  >
                    Soportamos formato .csv y .xls
                  </p>
                </div>
              </div>
            )}
          </div>
        </section>
      )}
    </Dropzone>
  );
};

export default BankExtractFileUpload;
