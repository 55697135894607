import React from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { Modal } from "react-bootstrap";
import { styles } from "./styles";
import { PrimaryButton, SecondaryButton } from "../../buttons";
import { NumericFormat } from "react-number-format";

export const PartiallyReconciledDetailModal = ({
  visible,
  setIsPartiallyReconciledDetailModalVisible,
  movement,
  reconciliationSet,
  setUnreconcileModalVisible,
  setUnreconcileSet,
}) => {
  return (
    <Modal
      show={visible}
      onHide={() => setIsPartiallyReconciledDetailModalVisible(false)}
      centered
    >
      <Modal.Body style={{ boxShadow: "0 5px 15px rgba(0, 0, 0, 0.5)" }}>
        <Box sx={styles.container}>
          <p style={styles.title}>Contabilidad</p>
          <div
            style={{
              backgroundColor: "#F9FAFB",
              padding: "16px",
              borderRadius: "8px",
              gap: "12px",
              width: "100%",
              alignSelf: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "normal",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                color: "#1D2939",
                alignSelf: "flex-start",
              }}
            >
              Se realizó una conciliación parcial de este movimiento bancario
            </Typography>
            <div
              style={{
                borderRadius: "8px",
                padding: "16px",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#FFFFFF",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#344054",
                  }}
                >
                  Conciliado
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#344054",
                  }}
                >
                  Saldo pendiente de conciliación
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                  justifyContent: "flex-end",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#111322",
                    alignSelf: "flex-end",
                  }}
                >
                  <NumericFormat
                    value={parseInt(
                      movement?.related_receipts?.reduce(
                        (acc, movement) => acc + movement.amount,
                        0
                      )
                    )}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    decimalScale={2}
                    prefix={"$"}
                  />
                </Typography>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: "600",
                    color: "#B42318",
                    alignSelf: "flex-end",
                  }}
                >
                  <NumericFormat
                    value={parseInt(
                      movement?.related_movements?.reduce(
                        (acc, movement) => acc + movement.amount,
                        0
                      ) -
                        movement?.related_receipts?.reduce(
                          (acc, receipt) => acc + receipt.amount,
                          0
                        )
                    )}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    decimalScale={2}
                    prefix={"$"}
                  />
                </Typography>
              </div>
            </div>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                color: "#344054",
              }}
            >
              Movimientos bancarios
            </Typography>
            <div
              style={{
                borderRadius: "8px",
                padding: "16px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#FFFFFF",
                gap: "12px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "#101828",
                  }}
                >
                  Número de comprobante
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "#101828",
                  }}
                >
                  Monto registrado
                </Typography>
              </div>
              <Divider
                sx={{
                  backgroundColor: "#EBECF0",
                  height: "2px",
                  width: "100%",
                }}
              />
              {movement?.related_movements?.map((movement) => {
                return (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "#475467",
                        maxWidth: "500px",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {movement.description}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "#475467",
                        textAlign: "flex-end",
                        alignSelf: "flex-end",
                      }}
                    >
                      <NumericFormat
                        value={parseInt(movement.amount)}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={2}
                        prefix={"$"}
                      />
                    </Typography>
                  </div>
                );
              })}
            </div>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                color: "#344054",
              }}
            >
              Registros contables
            </Typography>
            <div
              style={{
                borderRadius: "8px",
                padding: "16px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#FFFFFF",
                gap: "12px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "#101828",
                  }}
                >
                  Número de comprobante
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "#101828",
                  }}
                >
                  Monto registrado
                </Typography>
              </div>
              <Divider
                sx={{
                  backgroundColor: "#EBECF0",
                  height: "2px",
                  width: "100%",
                }}
              />
              {movement?.related_receipts?.map((receipt) => {
                return (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "#475467",
                        maxWidth: "275px",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {receipt.accounting_receipt_number}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "#475467",
                        textAlign: "flex-end",
                        alignSelf: "flex-end",
                      }}
                    >
                      <NumericFormat
                        value={parseInt(receipt.amount)}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={2}
                        prefix={"$"}
                      />
                    </Typography>
                  </div>
                );
              })}
            </div>
          </div>
          <Stack
            direction={"row"}
            spacing={1}
            pt={4}
            justifyContent="flex-end"
            alignSelf="flex-end"
          >
            <SecondaryButton
              text={"Desconciliar"}
              action={() => {
                setIsPartiallyReconciledDetailModalVisible(false);
                setUnreconcileSet(reconciliationSet);
                setUnreconcileModalVisible(true);
              }}
              width="114px"
            />
            <PrimaryButton
              text={"Entendido"}
              action={() => setIsPartiallyReconciledDetailModalVisible(false)}
              width="114px"
            />
          </Stack>
        </Box>
      </Modal.Body>
    </Modal>
  );
};
